import * as React from "react";
import IndataStepComponent from "../../indata-step";
import { useSelector, useDispatch } from "react-redux";
import { type IRootState, addAdditionalHousing, removeAdditionalHousing } from "../../../reducers/root-reducer";
import { useTranslation } from "react-i18next";
import RadioComponent from "../../form-controls/radio-component";
import { InputState } from "../../../models/input-state";
import DisclaimerComponent from "../../typography/disclaimer-component";

export default function KalpAdditionalHousingChoiceStepComponent(props) {
    const { additionalHousing } = useSelector((state: IRootState) => {
        return {
            additionalHousing: state.userEditingData?.kalp?.additionalHousing,
        };
    });

    const dispatch = useDispatch();
    const { t } = useTranslation();

    function onChangeAdditionalHousing(value) {
        new Promise<void>((resolve) => {
            if (value === "yes") {
                dispatch(addAdditionalHousing());
            } else if (value === "no") {
                dispatch(removeAdditionalHousing());
            }
            resolve();
        }).then(() => {
            props.nextStep();
        });
    }

    function getAdditionalHousingValue() {
        switch (additionalHousing?.state) {
            case InputState.NotSpecified:
                return null;
            case undefined:
                return null;
            default:
                return additionalHousing?.data ? "yes" : "no";
        }
    }

    React.useEffect(() => {
        props.onValidate(additionalHousing?.state === InputState.Specified);
    });

    const additionalHouseOptions = [
        { label: t("common:yes"), value: "yes" },
        { label: t("common:no"), value: "no" },
    ];

    return (
        <IndataStepComponent active={props.active}>
            <div className="additional-housing-choice">
                <RadioComponent
                    label={t("kalp:input-additional-housings")}
                    small={true}
                    options={additionalHouseOptions}
                    value={getAdditionalHousingValue()}
                    onChange={onChangeAdditionalHousing}
                />
            </div>
            <DisclaimerComponent>{t("kalp:input-additional-housings-disclaimer")}</DisclaimerComponent>
        </IndataStepComponent>
    );
}
