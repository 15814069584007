import type { IInterestDiscountLevel } from "../modules/tips/interest-discount-new-loan";
import type { IRootState } from "../reducers/root-reducer";

export interface ITipsModuleProps {
    rootState: IRootState;
    configuration?: { discounts: Array<IInterestDiscountLevel> };
    onActivated: () => void;
}

export interface ITipsModule {
    id: string;
    activationFunction: (rootState: IRootState, configuration?: { discounts: Array<IInterestDiscountLevel> }) => boolean;
    component: React.FunctionComponent<ITipsModuleProps> | React.ComponentClass<ITipsModuleProps>;
}

export class ModuleStore {
    public static tipsModules: {
        [id: string]: ITipsModule;
    } = {};

    public static getTipsModule(id: string): ITipsModule {
        return ModuleStore.tipsModules[id];
    }
}

export function tipsModule(id: string, activationFunction: (rootState: IRootState) => boolean) {
    return (component: React.FunctionComponent<ITipsModuleProps> | React.ComponentClass<ITipsModuleProps>) => {
        ModuleStore.tipsModules[id] = {
            id,
            activationFunction,
            component,
        };
        return component;
    };
}
