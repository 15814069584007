import * as React from "react";
import IndataStepComponent from "../../indata-step";
import { useSelector, useDispatch } from "react-redux";
import { type IRootState, setPrivateLoanAmount, setPrivateLoanAmortization } from "../../../reducers/root-reducer";
import NumberComponent from "../../form-controls/number-component";
import { useTranslation } from "react-i18next";
import {
    MAX_STUDENT_LOAN_AMORTIZATION,
    MAX_STUDENT_LOAN_AMOUNT,
    MIN_PRIVATE_LOAN_AMOUNT,
    MIN_STUDENT_LOAN_AMORTIZATION,
    PRIVATE_LOAN_AMOUNT_STEP,
    STUDENT_LOAN_AMORTIZATION_STEP,
} from "../../../defaults";
import OverlineComponent from "../../typography/overline-component";

export default function PrivateLoanFirstStepComponent(props) {
    const { amount, amortization } = useSelector((state: IRootState) => {
        return {
            amount: state.userEditingData.loans.privateLoans[props.index].amount,
            amortization: state.userEditingData.loans.privateLoans[props.index].amortization,
        };
    });

    const dispatch = useDispatch();
    const { t } = useTranslation();

    function onChangeFirst(value: number) {
        props.onValidate(true);
        dispatch(setPrivateLoanAmount({ index: props.index, value: value }));
    }

    function onChangeSecond(value: number) {
        props.onValidate(true);
        dispatch(setPrivateLoanAmortization({ index: props.index, value: value }));
    }

    React.useEffect(() => {
        props.onValidate(true);
    });

    return (
        <IndataStepComponent active={props.active}>
            <OverlineComponent>{t("loan:privateloan-enumeration", { index: props.index + 1 })}</OverlineComponent>
            <NumberComponent
                name={`loan_privateloan_${props.index}_amount`}
                unit={t("common:sek")}
                onChange={onChangeFirst}
                min={MIN_PRIVATE_LOAN_AMOUNT}
                step={PRIVATE_LOAN_AMOUNT_STEP}
                defaultMax={MAX_STUDENT_LOAN_AMOUNT}
                label={t("loan:input-privateloan-amount")}
                value={amount}
            />
            <NumberComponent
                name={`loan_privateloan_${props.index}_amortization`}
                unit={t("common:sek")}
                onChange={onChangeSecond}
                min={MIN_STUDENT_LOAN_AMORTIZATION}
                step={STUDENT_LOAN_AMORTIZATION_STEP}
                defaultMax={MAX_STUDENT_LOAN_AMORTIZATION}
                label={t("loan:input-privateloan-amortization")}
                value={amortization}
            />
        </IndataStepComponent>
    );
}
