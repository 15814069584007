import React from "react";
import type { IRootState } from "../../reducers/root-reducer";
import { useSelector } from "react-redux";
import { constructUserDataInterface, type IUserDataInterface } from "../../user-data-interface";
import { useTracking } from "react-tracking";
import { trackLead } from "../../utility/analytics";
import EffectiveInterestCalculator from "../../functions/effective-interest";
import { formatLocalAmount } from "../../utility/number-formatter";
import { useTranslation } from "react-i18next";
import DisclaimerComponent from "../typography/disclaimer-component";
import { AccessibleButton } from "../accessibility/accessible-components";
import Config from "../../config";

export interface InterestOfferConfig {
    name: string;
    description: string;
    interest: number;
    buttonText: string;
    disclaimer?: string;
    onClick: (userData: IUserDataInterface) => void;
}
export interface InterestOfferProps {
    config: InterestOfferConfig;
}

export default function InterestOfferComponent(props: InterestOfferProps) {
    const { config } = props;
    const tracking = useTracking();
    const { t } = useTranslation();

    const { userData } = useSelector((state: IRootState) => {
        return {
            userData: state.userData,
        };
    });

    const effectiveInterest = EffectiveInterestCalculator.withInterest(props.config.interest);
    const formattedEffectiveInterest = formatLocalAmount(Math.round(effectiveInterest * 10000) / 100, 2, 2);
    const formattedInterest = formatLocalAmount(Math.round(props.config.interest * 10000) / 100, 2, 2);

    function onClick() {
        if (config.onClick) {
            tracking.trackEvent(trackLead("click-offer", `offer-${config.name}`));
            config.onClick(constructUserDataInterface(userData));
        }
    }

    const hideEffectiveInterest = Config.get("hideEffectiveInterest");

    return (
        <React.Fragment>
            <h4>{config.description}</h4>
            <div className="details">
                {!hideEffectiveInterest && (
                    <h2>
                        <small>{t("common:interest")}:</small>
                    </h2>
                )}
                <h2>{formattedInterest} %</h2>
                {!hideEffectiveInterest && (
                    <>
                        <h2>
                            <small>{t("common:effective-interest")}:</small>
                        </h2>
                        <h2>{formattedEffectiveInterest} %</h2>
                    </>
                )}
            </div>
            <AccessibleButton className="lead" onClick={onClick}>
                {config.buttonText}
            </AccessibleButton>
            {config.disclaimer && <DisclaimerComponent>{config.disclaimer}</DisclaimerComponent>}
        </React.Fragment>
    );
}
