import * as React from "react";
import InputWizardComponent from "../input-wizard";
import { type IRootState, saveLoan, setNavigation, ViewMode, Sections } from "../../reducers/root-reducer";
import { useSelector } from "react-redux";
import LoanFirstStepComponent from "../steps/loan/loan-first-step";
import LoanSecondStepComponent from "../steps/loan/loan-second-step";
import StudentLoanStepComponent from "../steps/loan/loan-studentloan-step";
import PrivateLoanFirstStepComponent from "../steps/loan/loan-privateloan-first-step";
import PrivateLoanSecondStepComponent from "../steps/loan/loan-privateloan-second-step";
import CarLoanFirstStepComponent from "../steps/loan/loan-carloan-first-step";
import CarLoanSecondStepComponent from "../steps/loan/loan-carloan-second-step";
import { useTracking } from "react-tracking";
import { useDispatch } from "react-redux";
import { trackUserInput, UserInputActions, StepNames } from "../../utility/analytics";

export default function LoanWizard() {
    const dispatch = useDispatch();
    const tracking = useTracking();

    const { studentLoans, privateLoans, carLoans } = useSelector((state: IRootState) => {
        return {
            studentLoans: state.userEditingData?.loans?.studentLoans || [],
            privateLoans: state.userEditingData?.loans?.privateLoans || [],
            carLoans: state.userEditingData?.loans?.carLoans || [],
        };
    });

    function goToResult() {
        dispatch(saveLoan());
        tracking.trackEvent(trackUserInput(UserInputActions.STEP_COMPLETED, StepNames.LOAN));
        dispatch(
            setNavigation({
                viewMode: ViewMode.Result,
                section: Sections.Loans,
            }),
        );
    }

    function emitStepStarted() {
        tracking.trackEvent(trackUserInput(UserInputActions.STEP_STARTED, StepNames.LOAN));
    }

    const steps = [<LoanFirstStepComponent key="first" />, <LoanSecondStepComponent key="second" />];

    for (let key = 0; key < studentLoans.length; ++key) {
        steps.push(<StudentLoanStepComponent key={`studentloan_${key}`} index={key} />);
    }

    for (let key = 0; key < privateLoans.length; ++key) {
        steps.push(<PrivateLoanFirstStepComponent key={`privateloan_${key}_first`} index={key} />);
        steps.push(<PrivateLoanSecondStepComponent key={`privateloan_${key}_second`} index={key} />);
    }

    for (let key = 0; key < carLoans.length; ++key) {
        steps.push(<CarLoanFirstStepComponent key={`carloan_${key}_first`} index={key} />);
        steps.push(<CarLoanSecondStepComponent key={`carloan_${key}_second`} index={key} />);
    }

    return (
        <InputWizardComponent onFinalStep={goToResult} onFirstStep={emitStepStarted}>
            {steps}
        </InputWizardComponent>
    );
}
