import type { IUserDataInterface } from "../user-data-interface";

export interface ITrackData {
    event: IEvent;
    data?: IUserDataInterface;
}

export interface IEvent {
    category: string;
    action: string;
    label: string;
    value?: number;
    nonInteractive?: boolean;
}

export enum StepNames {
    HOUSING = "step-housing",
    KALP = "step-kalp",
    LOAN = "step-loan",
    SAVINGS = "step-savings",
}

export enum UserInputActions {
    STARTED = "started",
    STEP_STARTED = "step-started",
    STEP_COMPLETED = "step-completed",
}

export enum ScenarioEvent {
    MORTGAGE = "mortgage-scenario",
    INTEREST = "interest-scenario",
    AMORTIZATION = "amortization-scenario",
    MAINTENANCE = "maintenance-scenario",
    ADDITIONAL_CHILD = "additional-child-scenario",
    WORK_PART_TIME = "work-part-time-scenario",
    INVESTMENT = "investment-scenario",
    RENOVATION = "renovation-scenario",
}

export enum TooltipEvent {
    AREA_TOOLTIP = "area-tooltip",
    INFO_TOOLTIP = "info-tooltip",
    CHART_INFO_BUTTON = "chart-info-button",
}

export enum EventCategories {
    USER_INPUT = "user-input",
    LEAD = "lead",
    WIDGET_EVENT = "widget-event",
    ACTIVITY = "activity",
    ENGAGEMENT = "engagement",
    OFFER = "offer",
    TIPS = "tips",
}

export enum WidgetActions {
    LOADED = "loaded",
}

export enum TipsActions {
    APPLICABLE = "applicable",
    ACTIVATED = "activated",
    EXPANDED = "expanded",
    TIPS_CHANGED = "tips-changed",
}

export enum OfferActions {
    PRESENTED = "presented",
}

export function trackEngagement(label: string): ITrackData {
    return trackEvent(EventCategories.ENGAGEMENT, UserInputActions.STARTED, label);
}

export function trackUserInput(action: string, label?: string): ITrackData {
    return trackEvent(EventCategories.USER_INPUT, action, label);
}

export function trackLead(action: string, label?: string): ITrackData {
    return trackEvent(EventCategories.LEAD, action, label);
}

export function trackWidgetEvent(action: string, label?: string): ITrackData {
    return trackEvent(EventCategories.WIDGET_EVENT, action, label);
}

export function trackOffer(action: string, label: string): ITrackData {
    return trackEvent(EventCategories.OFFER, action, label);
}

export function trackTips(action: string, label: string): ITrackData {
    return trackEvent(EventCategories.TIPS, action, label);
}

export function trackEvent(category: string, action: string, label: string) {
    return { event: { label, action, category } };
}

export function trackActivity(time: number): ITrackData {
    return {
        event: {
            label: time.toString(),
            action: "user-activity",
            category: EventCategories.ACTIVITY,
            value: time,
            nonInteractive: true,
        },
    };
}
