import i18next from "i18next";

import type UserData from "../models/user-data";
import { type Child, ChildHouseholdRate, type Person } from "../models/user-data";
import { forecastHousingExpenses, forecastExistingMortgage } from "./household";
import { forecastMonthlyExpense } from "./loans";
import { ResultBuilder } from "./result-builder";
import type { DataPoint } from "../models/result";
import type { SectionBuilder } from "./section-builder";
import { householdIncomeForecastForYear } from "./income";
import { forecastSavingsExpenses } from "./savings";
import type { IRootState } from "../reducers/root-reducer";
import { applyBankKalpScenariosToUserData } from "./calculations";
import { getHousingPriceByCalculationType } from "../selectors/household";
import { MortgageCalculationType } from "../models/mortgage";
import Config from "../config";
import { DEFAULT_KALP_PARAMETERS, MAX_CHILD_AGE, RETIREMENT_AGE } from "../defaults";

export function isBankKalpPositivForLTV(state: IRootState, LTVRatio: number) {
    const { calculationInterestRate, userData, scenarioData } = state;
    const scenarioHousingValue = state.scenarioData?.mortgageData?.housingValue;

    const currentHousingValue = scenarioHousingValue || getHousingPriceByCalculationType(userData);
    const userDataWithMinLoan = applyBankKalpScenariosToUserData(
        userData,
        {
            ...scenarioData,
            mortgageData: {
                housingValue: currentHousingValue,
                mortgage: currentHousingValue * LTVRatio,
            },
        },
        calculationInterestRate,
    );
    const kalpWithMinLoan = forecastKalpWithInterest(userDataWithMinLoan, calculationInterestRate);

    return kalpWithMinLoan > 0;
}

export function isBankKalpNegativeOrZero(state: IRootState) {
    const { userData, scenarioData, calculationInterestRate } = state;

    const currentUserData = applyBankKalpScenariosToUserData(userData, scenarioData, calculationInterestRate);
    const kalpSumNow = forecastKalpWithInterest(currentUserData, calculationInterestRate);

    return kalpSumNow <= 0;
}

/*
 * Never use scenario defined amortization for the calculation interest kalp,
 * since this would give the user a false impression of the impact a higher
 * amortization rate would have on your creditworthiness.
 */
export function forecastKalpWithInterest(userData: UserData, interest: number): number {
    const newUserData = {
        ...userData,
        kalp: userData.kalp ?? DEFAULT_KALP_PARAMETERS,
        scenarioDefinedInterest: interest,
    };
    if (userData.household.calculationType === MortgageCalculationType.new) {
        newUserData.scenarioDefinedAmortization = null;
    }

    const calculationInterestKalp = forecastKalp(newUserData, 0);

    return calculationInterestKalp.result.summary.now;
}

export function forecastKalp(userData: UserData, nrOfYears: number, includeSavings?: boolean) {
    if (!userData.kalp) {
        return undefined;
    }

    const resultBuilder = new ResultBuilder(nrOfYears);
    const incomeSection = resultBuilder.createSection("income");
    const expenseSection = resultBuilder.createSection("expenses");
    // const renovationSection = resultBuilder.createSection("renovation") will we want this info in the resultsection? If so, add renovation to dynamic.json;

    const adults = userData.scenarioDefinedAdults ?? userData.household.adults ?? [];

    for (let i = 0; i <= nrOfYears; ++i) {
        const householdIncome = householdIncomeForecastForYear(userData, i).netIncome;
        incomeSection.addYear(i, householdIncome);

        adults.forEach((adult: Person, adultIndex: number) => {
            if (adult.age + i === RETIREMENT_AGE) {
                resultBuilder.addTooltip(i, i18next.t("kalp:adult-retires", { index: adultIndex + 1 }));
            } else if (adult.partTimeFrom === i && adult.employmentRate !== 1) {
                resultBuilder.addTooltip(
                    i,
                    i18next.t("kalp:adult-work-part-time", {
                        from: adultIndex + 1,
                        period: adult.partTimePeriod,
                    }),
                );
            }
        });
        const yearToday = new Date().getFullYear();
        if (userData.scenarioDefinedRenovationYear) {
            if (userData.scenarioDefinedRenovationYear === yearToday + i && userData.scenarioDefinedRenovationCost > 0) {
                resultBuilder.addTooltip(
                    i,
                    i18next.t("kalp:renovation-savings-done", {
                        index: userData.scenarioDefinedRenovationYear,
                    }),
                );
            }
        }
    }

    forecastExpenses(expenseSection, userData, nrOfYears, includeSavings);

    const sumFunction = (data: { [key: string]: DataPoint }) => {
        return data.income.future.map((inc, idx) => {
            return inc - data.expenses.future[idx];
        });
    };

    return resultBuilder.getResult(sumFunction);
}

function forecastExpenses(sectionBuilder: SectionBuilder, userData: UserData, nrOfYears: number, includeSavings: boolean) {
    const housingExpenses = forecastHousingExpenses(userData, nrOfYears).result.summary.future;
    const nonHousingLoans = forecastMonthlyExpense(userData, nrOfYears).future;
    const savingsExpenses = forecastSavingsExpenses(userData, nrOfYears).future;

    for (let i = 0; i <= nrOfYears; ++i) {
        sectionBuilder.addToYear(i, housingExpenses[i]);
        sectionBuilder.addToYear(i, nonHousingLoans[i]);
        if (includeSavings) {
            sectionBuilder.addToYear(i, savingsExpenses[i]);
        }

        const livingExpenses = livingExpensesInYear(sectionBuilder, userData, i);
        sectionBuilder.addToYear(i, livingExpenses);

        if (userData.kalp.additionalHousing) {
            sectionBuilder.addToYear(i, additionalHousingExpensesInYear(userData, i));
        }
    }
}

export function livingExpensesInYear(sectionBuilder: SectionBuilder, userData: UserData, year: number) {
    if (!userData.household?.adults) {
        return 0;
    }
    const { singleAdultHouseholdExpense, twoAdultHouseholdExpense } = Config.get("standardValues");

    const adultExpenses = userData.household.adults.length === 2 ? twoAdultHouseholdExpense : singleAdultHouseholdExpense;

    const children = userData.scenarioDefinedChildren
        ? (userData.kalp?.children || []).concat(userData.scenarioDefinedChildren)
        : userData.kalp?.children || [];
    const childExpenses = children
        .filter((child) => child.age !== undefined)
        .map((child, index) => {
            const c = forecastChild(child, year);
            return childExpense(sectionBuilder, index, c, year);
        })
        .reduce((acc, childCost) => acc + childCost, 0);

    const carCost = userData.kalp?.carCost || 0;
    return adultExpenses + childExpenses + carCost;
}

function additionalHousingExpensesInYear(userData: UserData, year: number) {
    const additionalHousing = userData.kalp.additionalHousing;
    if (!additionalHousing.data) {
        return 0;
    }

    const forecastedMortgage = forecastExistingMortgage(additionalHousing.data.mortgage, year);

    const monthlyPropertyTax = Math.round(additionalHousing.data.propertyTax / 12);
    const housingFees = additionalHousing.data.maintenance + additionalHousing.data.fees + monthlyPropertyTax;
    const loanFees = forecastedMortgage.amortization + forecastedMortgage.interestExpense;

    return housingFees + loanFees;
}

function forecastChild(child: Child, years: number) {
    return { ...child, age: child.age + years };
}

function childExpense(sectionBuilder: SectionBuilder, childIndex: number, child: Child, year: number) {
    if (child.age === MAX_CHILD_AGE + 1) {
        sectionBuilder.addTooltip(year, i18next.t("kalp:child-move-out", { index: childIndex + 1 }));
    }
    if (child.age === 0) {
        sectionBuilder.addTooltip(year, i18next.t("kalp:child-is-born", { index: childIndex + 1 }));
    }
    if (child.age > 20 || child.age < 0) {
        return 0;
    }

    const { childFullTimeExpense, childHalfTimeExpense } = Config.get("standardValues");

    if (child.householdRate === ChildHouseholdRate.FULL_TIME) {
        return childFullTimeExpense;
    }
    return childHalfTimeExpense;
}
