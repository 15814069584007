import type UserData from "./models/user-data";
import { getHouseholdIncome, getTotalMortgageAmount, getHousingPriceByCalculationType } from "./selectors/household";
import type Result from "./models/result";

export interface IUserDataInterface {
    housingType: string;
    calculationInterestKalp?: number;
    calculationType?: string;
    housingPrice?: number;
    householdIncome?: number;
    mortgageAmount?: number;
    propertyTax?: number;
    fee?: number;
    maintenance?: number;
    downpayment?: number;
}

export const constructUserDataInterface = (userData?: UserData, result?: Result): IUserDataInterface => {
    return {
        calculationInterestKalp: result?.calculationInterestKalp,
        calculationType: userData?.household?.calculationType?.toString(),
        householdIncome: userData && getHouseholdIncome(userData),
        housingPrice: userData && getHousingPriceByCalculationType(userData),
        mortgageAmount: userData && getTotalMortgageAmount(userData),
        housingType: userData?.household?.housingType?.toString() || "",
        propertyTax: userData?.household?.propertyTax,
        fee: userData?.household?.fee,
        maintenance: userData?.household?.maintenance,
        downpayment: userData?.household?.downpayment,
    };
};
