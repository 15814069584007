import * as React from "react";

import InsightsChart from "./insights-chart";
import type { ILoansResult, IResult } from "../../models/result";
import type { TooltipEvent } from "../../utility/analytics";

export interface ILoansChartProps {
    result: ILoansResult;
    period: number;
    onEngagement: (label: TooltipEvent) => void;
}

export class LoansChart extends React.Component<ILoansChartProps> {
    render() {
        const { period, result, onEngagement } = this.props;

        if (!result) {
            return;
        }

        const data: IResult = {};
        if (result.additionalHousingLoans) {
            data.additionalHousingLoans = result.additionalHousingLoans;
        }
        data.carLoans = result.carLoans;
        data.privateLoans = result.privateLoans;
        data.studentLoan = result.studentLoan;
        data.housingLoan = result.housingLoan;

        return <InsightsChart id="loans" period={period} summary={result.summary} data={data} className="loans" onEngagement={onEngagement} />;
    }
}
