import * as React from "react";

import InsightsChart from "./insights-chart";
import type { ISavingsResult, IResult } from "../../models/result";
import type { IInfoTooltipProps } from "./chart";
import type { TooltipEvent } from "../../utility/analytics";

export interface ISavingsChartProps {
    result: ISavingsResult;
    period: number;
    tooltips?: Array<IInfoTooltipProps>;
    onEngagement: (label: TooltipEvent) => void;
}

export class SavingsChart extends React.Component<ISavingsChartProps> {
    render() {
        const { period, result, onEngagement } = this.props;

        if (!result) {
            return;
        }

        // The order of the keys here is important. We want the savings chart to
        // present the result in the same order as the savings table. The
        // insight chart currently reverses the order of the keys though.
        const data: IResult = {};

        // biome-ignore lint/complexity/noForEach: <explanation>
        Object.keys(result)
            .filter((k) => ["investments", "bankAccounts", "summary"].indexOf(k) < 0)
            .forEach((k) => {
                data[k] = result[k];
            });
        data.bankAccounts = result.bankAccounts;
        data.investments = result.investments;

        return (
            <InsightsChart
                id="savings"
                period={period}
                summary={result.summary}
                data={data}
                className="savings"
                infoTooltips={this.props.tooltips}
                onEngagement={onEngagement}
            />
        );
    }
}
