import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { tipsModule, type ITipsModuleProps } from "../../utility/module-store";
import { type IRootState, setMortgageScenario } from "../../reducers/root-reducer";
import { getMortgage, getMonthlyInterestExpenseAfterDeduction, getInterestRate, isLoanOutsideLTVLimits } from "../../functions/household";
import { isBankKalpNegativeOrZero } from "../../functions/kalp";
import { formatLocalAmount } from "../../utility/number-formatter";
import DisclaimerComponent from "../../components/typography/disclaimer-component";
import EffectiveInterestCalculator from "../../functions/effective-interest";
import { MortgageCalculationType } from "../../models/mortgage";
import { processMarkdown } from "../../utility/markdown-processor";
import { applyBankKalpScenariosToUserData } from "../../functions/calculations";
import { getHousingPriceByCalculationType } from "../../selectors/household";
import { AccessibleButton } from "../../components/accessibility/accessible-components";

const LOWEST_CHANGE_FACTOR_LOAN = 0.83;

export interface IInterestDiscountLevel {
    rate: number;
    interest: number;
    discount: number;
    contract: string;
}

function findDiscountedInterestRate(ltv: number, discountLevels: Array<IInterestDiscountLevel>): IInterestDiscountLevel {
    const rate = discountLevels.sort((first, second) => second.rate - first.rate).find((discount) => discount.rate < ltv);
    if (!rate) {
        return undefined;
    }

    return rate;
}

function findDownpaymentDelta(value: number, mortgage: number, discountLevels: Array<IInterestDiscountLevel>) {
    const ltv = value <= 0 ? 0 : mortgage / value;
    const interestRate = findDiscountedInterestRate(ltv, discountLevels);

    const delta = mortgage - interestRate.rate * value;

    return delta;
}

function isDownPaymentAppropriate(housingValue: number, mortgage: number, discountLevels: Array<IInterestDiscountLevel>) {
    const currentLTV = mortgage / housingValue;
    const closestThresholdBelow = findDiscountedInterestRate(currentLTV, discountLevels)?.rate;
    if (!closestThresholdBelow) {
        return false;
    }

    const minAppropriateMortgage = mortgage * LOWEST_CHANGE_FACTOR_LOAN;
    const minAppropriateLTV = minAppropriateMortgage / housingValue;

    return minAppropriateLTV <= closestThresholdBelow;
}

export function isTipsActive(rootState: IRootState, discountLevels: Array<IInterestDiscountLevel>) {
    const { userData, scenarioData } = rootState;
    if (!userData?.household || !discountLevels) {
        return false;
    }

    if (isBankKalpNegativeOrZero(rootState)) {
        return false;
    }

    const maxLTVRatio = 1 - rootState.minDownpaymentRate;
    if (isLoanOutsideLTVLimits(rootState, { maxLTVRatio })) {
        return false;
    }

    const currentUserData = applyBankKalpScenariosToUserData(userData, scenarioData, scenarioData?.mortgageInterestRate?.interest);
    const value = getHousingPriceByCalculationType(currentUserData);
    if (value <= 0) {
        return false;
    }

    const mortgage = getMortgage(currentUserData) || 0;
    if (!isDownPaymentAppropriate(value, mortgage, discountLevels)) {
        return false;
    }

    const ltv = value <= 0 ? 0 : mortgage / value;
    const originalInterest = getInterestRate(currentUserData);
    const discountedInterestRate = findDiscountedInterestRate(ltv, discountLevels);

    return !!discountedInterestRate && discountedInterestRate.interest < originalInterest;
}

function contractAsString(contract: string) {
    const { t } = useTranslation();

    const match = contract.match(/^(\d+)\s?([ym])$/);
    if (!match) {
        return null;
    }

    const time = Number.parseInt(match[1]);
    const unit = match[2];

    if (unit === "y" || unit === "Y") {
        return t("common:n-years", { years: time });
    }
    if (unit === "m" || unit === "M") {
        return t("common:n-months", { months: time });
    }

    return null;
}

export function InterestDiscountTipsComponent(props: ITipsModuleProps) {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { userData, scenarioData } = props.rootState;
    const currentUserData = applyBankKalpScenariosToUserData(userData, scenarioData, scenarioData?.mortgageInterestRate?.interest);
    const houseValue = getHousingPriceByCalculationType(currentUserData);
    const mortgage = getMortgage(currentUserData) || 0;
    const ltv = houseValue <= 0 ? 0 : mortgage / houseValue;
    const originalInterest = getInterestRate(currentUserData);

    const discountInterest = findDiscountedInterestRate(ltv, props.configuration.discounts);
    const downpaymentDelta = findDownpaymentDelta(houseValue, mortgage, props.configuration.discounts);

    const yearlyInterest = getMonthlyInterestExpenseAfterDeduction(mortgage, 0, originalInterest) * 12;
    const discountedYearlyInterest = getMonthlyInterestExpenseAfterDeduction(mortgage - downpaymentDelta, 0, discountInterest.interest) * 12;
    const discountSaving = yearlyInterest - discountedYearlyInterest;
    const downpaymentTotal = houseValue - mortgage + downpaymentDelta;
    const effectiveDiscountedInterest = EffectiveInterestCalculator.withInterest(discountInterest.interest);

    const calculationType = userData.household.calculationType;
    const { rate, interest, discount, contract } = discountInterest;
    const tipsText =
        calculationType === MortgageCalculationType.new
            ? t("tips:increase-downpayment-to-discount-new-loan", {
                  downpaymentDelta: formatLocalAmount(downpaymentDelta),
                  discountSaving: formatLocalAmount(discountSaving),
                  discountInterest: formatLocalAmount(interest * 100, 0, 2),
              })
            : t("tips:increase-downpayment-to-discount-move-loan", {
                  downpaymentDelta: formatLocalAmount(downpaymentDelta),
                  loanToValueRatio: rate * 100,
                  discountInterest: formatLocalAmount(interest * 100, 0, 2),
              });
    const tipsDisclaimer =
        calculationType === MortgageCalculationType.new
            ? t("tips:increase-downpayment-to-discount-new-loan-disclaimer", {
                  downpaymentTotal: formatLocalAmount(downpaymentTotal),
                  loanToValueRatio: rate * 100,
                  interestRateDiscount: formatLocalAmount(discount * 100, 0, 2),
                  discountInterest: formatLocalAmount(interest * 100, 0, 2),
                  contract: contractAsString(contract),
                  effectiveInterest: formatLocalAmount(effectiveDiscountedInterest * 100, 0, 2),
              })
            : t("tips:increase-downpayment-to-discount-move-loan-disclaimer", {
                  downpaymentDelta: formatLocalAmount(downpaymentDelta),
                  loanToValueRatio: rate * 100,
                  interestRateDiscount: formatLocalAmount(discount * 100, 0, 2),
                  discountInterest: formatLocalAmount(interest * 100, 0, 2),
                  contract: contractAsString(contract),
                  effectiveInterest: formatLocalAmount(effectiveDiscountedInterest * 100, 0, 2),
              });

    function setScenario() {
        props.onActivated();
        dispatch(
            setMortgageScenario({
                value: houseValue,
                mortgage: mortgage - downpaymentDelta,
                mortgageInterestRate: { interest: discountInterest.interest },
            }),
        );
    }

    return (
        <div>
            {/* biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation> */}
            <h4 dangerouslySetInnerHTML={{ __html: processMarkdown(tipsText) }} />
            <AccessibleButton onClick={setScenario}>{t("tips:set-scenario")}</AccessibleButton>
            <DisclaimerComponent>
                <span
                    // biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
                    dangerouslySetInnerHTML={{
                        __html: processMarkdown(tipsDisclaimer),
                    }}
                />
            </DisclaimerComponent>
            <DisclaimerComponent>{t("tips:no-offer")}</DisclaimerComponent>
        </div>
    );
}

export default tipsModule(
    "interest-discount-new-loan",
    (state: IRootState, configuration?) =>
        state?.userData?.household?.calculationType === MortgageCalculationType.new && isTipsActive(state, configuration?.discounts),
)(InterestDiscountTipsComponent);
