import { createReducer, createAction } from "typesafe-actions";
import type UserData from "../models/user-data";
import {
    type Child,
    ChildHouseholdRate,
    type HousingType,
    type KalpData,
    type HouseholdData,
    type LoanData,
    type SavingsData,
    type Person,
} from "../models/user-data";
import {
    setStudentLoansFn,
    setPrivateLoansFn,
    setCarLoansFn,
    setLoanAmortizationFn,
    setLoanAmountFn,
    setLoanInterestFn,
} from "./loan-reduce-actions";
import type IOffer from "../models/offer";
import type Result from "../models/result";
import { calculateResult } from "../functions/calculations";
import {
    DEFAULT_ADULT_AGE,
    DEFAULT_ADULT_INCOME,
    DEFAULT_HOUSEHOLD_PARAMETERS,
    DEFAULT_KALP_PARAMETERS,
    DEFAULT_LOAN_PARAMETERS,
    DEFAULT_SAVINGS_PARAMETERS,
    MONTHS_IN_YEAR,
    HIGH_AMORTIZATION_RATE_REQUIREMENT,
    DEFAULT_MORTGAGE_AMORTIZATION,
    DEFAULT_MORTGAGE_INTEREST,
    DEFAULT_PROPERTY_TAX,
    DEFAULT_ADDITIONAL_HOUSING_INTEREST_RATE,
} from "../defaults";
import Config from "../config";
import { deepClone } from "../utility/object";
import { type IMortgageConfiguration, MortgageCalculationType } from "../models/mortgage";
import { getMaintenance, getFee, getPropertyTax } from "../functions/housing-type";
import { InputState } from "../models/input-state";
import type { ITrackData } from "../utility/analytics";
import { hasOccured } from "../utility/one-time-events";
import { getMortgage } from "../functions/household";

export interface IRootState {
    forecastPeriod: number;
    minDownpaymentRate: number;
    calculationInterestRate: number;
    userData: UserData;
    userEditingData?: UserData;
    scenarioData?: IScenarioData;
    result?: Result;
    selectedSection: string;
    sectionCollapsed: boolean;
    selectedViewMode: ViewMode;
    offers?: Array<IOffer>;
    mortgage?: IMortgageConfiguration;
    events?: Array<ITrackData>;
    minLTVForTips: number;
    ratingShown?: boolean;
}

interface IMortgageInterestRateScenario {
    fixationPeriod?: string;
    interest: number;
}

export interface IHousingLoanScenario {
    housingValue: number;
    mortgage: number;
}

export interface IAmortizationScenario {
    amortizationValue?: number;
    usingRequirements?: boolean;
}

export interface IScenarioData {
    mortgageInterestRate?: IMortgageInterestRateScenario;
    mortgageData?: IHousingLoanScenario;
    mortgageAmortization?: IAmortizationScenario;
    mortgageMaintenance?: number;
    additionalChild?: Array<Child>;
    investmentMonthly?: number;
    investmentInterest?: number;
    adultsWorkPartTime?: Array<Person>;
    renovationTarget?: string;
    renovationCost?: number;
    renovationYear?: number;
}

export enum ViewMode {
    Input = "input",
    Result = "result",
}

export enum Sections {
    Savings = "savings",
    Loans = "loans",
    Housing = "housing",
    Kalp = "kalp",
}

export interface IArrayValue {
    index: number;
    value: number;
}

function resetScenarioData(state: IRootState, ...sections) {
    if (!state.scenarioData) {
        return;
    }
    if (!sections || sections.length <= 0) {
        state.scenarioData = undefined;
        return;
    }
    if (sections.indexOf(Sections.Housing) >= 0) {
        state.scenarioData.adultsWorkPartTime = undefined;
        state.scenarioData.mortgageAmortization = undefined;
        state.scenarioData.mortgageData = undefined;
        state.scenarioData.mortgageInterestRate = undefined;
        state.scenarioData.mortgageMaintenance = undefined;
    }
    if (sections.indexOf(Sections.Kalp) >= 0) {
        state.scenarioData.additionalChild = undefined;
    }
    // if (sections.indexOf(Sections.Loans) >= 0) {
    // Fill in here and uncomment once we implement any loan scenarios
    // }
    if (sections.indexOf(Sections.Savings) >= 0) {
        state.scenarioData.investmentInterest = undefined;
        state.scenarioData.investmentMonthly = undefined;
    }
}

function deepMerge<T>(...args): T {
    const target = {};
    // Merge the object into the target object
    const merger = (obj) => {
        for (const prop in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, prop)) {
                if (Object.prototype.toString.call(obj[prop]) === "[object Object]") {
                    // If we're doing a deep merge
                    // and the property is an object
                    target[prop] = deepMerge(target[prop], obj[prop]);
                } else {
                    // Otherwise, do a regular merge
                    target[prop] = obj[prop];
                }
            }
        }
    };
    //Loop through each object and conduct a merge
    for (let i = 0; i < args.length; i++) {
        merger(args[i]);
    }
    return target as T;
}

export function ensure(arrayName: string, state: IRootState) {
    const newState = Object.assign({}, state);
    if (!newState.userEditingData) {
        if (newState.userData) {
            newState.userEditingData = deepClone(newState.userData);
        } else {
            const housingDefault =
                deepMerge<HouseholdData>(DEFAULT_HOUSEHOLD_PARAMETERS, Config.get("housingDefaults")) || DEFAULT_HOUSEHOLD_PARAMETERS;
            const kalpDefault = deepMerge<KalpData>(DEFAULT_KALP_PARAMETERS, Config.get("kalpDefaults")) || DEFAULT_KALP_PARAMETERS;
            const loansDefaults = deepMerge<LoanData>(DEFAULT_LOAN_PARAMETERS, Config.get("loansDefaults")) || DEFAULT_LOAN_PARAMETERS;
            const savingsDefault = deepMerge<SavingsData>(DEFAULT_SAVINGS_PARAMETERS, Config.get("savingsDefaults")) || DEFAULT_SAVINGS_PARAMETERS;
            newState.userEditingData = {
                household: housingDefault,
                kalp: kalpDefault,
                loans: loansDefaults,
                savings: savingsDefault,
            };
        }
    }
    if (!newState.userEditingData[arrayName]) {
        newState.userEditingData[arrayName] = {};
    }

    return newState;
}

function ensureDefaults(userData: UserData) {
    const kalp = userData.kalp ?? (Config.get("kalpDefaults") || DEFAULT_KALP_PARAMETERS);
    const loans = userData.loans ?? (Config.get("loansDefaults") || DEFAULT_LOAN_PARAMETERS);
    const savings = userData.savings ?? (Config.get("savingsDefaults") || DEFAULT_SAVINGS_PARAMETERS);

    return { ...userData, kalp, loans, savings };
}

export const setUserData = createAction("root/SETUSERDATA")<UserData>();

export const setForecastPeriod = createAction("root/SETPERIOD")<number>();
export const setMortgageCalculationType = createAction("housing/SETCALCULATIONTYPE")<{
    type: MortgageCalculationType;
    useAmortizationRequirement?: boolean;
}>();
export const setHousingType = createAction("housing/SETFORM")<HousingType>();
export const setHousingValue = createAction("housing/SETVALUE")<number>();
export const setHousingDownpayment = createAction("housing/SETDOWNPAYMENT")<number>();
export const setHousingAdults = createAction("housing/SETHOUSINGADULTS")<number>();
export const setAdultAge = createAction("housing/SETADULTAGE")<IArrayValue>();
export const setAdultIncome = createAction("housing/SETADULTINCOME")<IArrayValue>();
export const setHousingMaintenance = createAction("housing/SETMAINTENANCE")<number>();
export const setHousingFee = createAction("housing/SETFEE")<number>();
export const setHousingPropertyTax = createAction("housing/SETPROPERTYTAX")<number>();
export const setHousingEstimatedValue = createAction("housing/SETESTIMATEDVALUE")<number>();
export const setHousingMortgages = createAction("housing/SETHOUSINGLOANS")<number>();
export const setMortgageAmount = createAction("housing/SETMORTGAGEAMOUNT")<IArrayValue>();
export const setMortgageInterest = createAction("housing/SETMORTGAGEINTEREST")<IArrayValue>();
export const setMortgageAmortization = createAction("housing/SETMORTGAGEAMORTIZATION")<IArrayValue>();
export const saveHousing = createAction("housing/SAVEHOUSING")();

export const setKalpChildren = createAction("kalp/SETCHILDREN")<number>();
export const setChildAge = createAction("kalp/SETCHILDAGE")<IArrayValue>();
export const setChildHouseholdRate = createAction("kalp/SETCHILDHOUSEHOLDRATE")<{ index: number; value: ChildHouseholdRate }>();
export const setCarCost = createAction("kalp/SETCARCOST")<number>();
export const saveKalp = createAction("kalp/SAVEKALP")();
export const addAdditionalHousing = createAction("kalp/ADDADITIONALHOUSING")();
export const removeAdditionalHousing = createAction("kalp/REMOVEADDITIONALHOUSING")();
export const setAdditionalHousingMortgageAmount = createAction("kalp/SETADDITIONALMORTGAGEAMOUNT")<number>();
export const setAdditionalHousingMortgageAmortization = createAction("kalp/SETADDITIONALMORTGAGEAMORTIZATION")<number>();
export const setAdditionalHousingPropertyTax = createAction("housing/SETADDITIONALPROPERTYTAX")<number>();
export const setAdditionalHousingFees = createAction("kalp/SETADDITIONALFEES")<number>();
export const setAdditionalHousingMaintenance = createAction("kalp/SETADDITIONALMAINTENANCE")<number>();

export const setStudentLoans = createAction("loans/SETSTUDENTLOANS")<number>();
export const setPrivateLoans = createAction("loans/SETPRIVATELOANS")<number>();
export const setCarLoans = createAction("loans/SETCARLOANS")<number>();
export const setStudentLoanAmount = createAction("loans/SETSTUDENTLOANAMOUNT")<IArrayValue>();
export const setStudentLoanAmortization = createAction("loans/SETSTUDENTLOANAMORTIZATION")<IArrayValue>();
export const setPrivateLoanAmount = createAction("loans/SETPRIVATELOANAMOUNT")<IArrayValue>();
export const setPrivateLoanAmortization = createAction("loans/SETPRIVATELOANAMORTIZATION")<IArrayValue>();
export const setPrivateLoanInterest = createAction("loans/SETPRIVATELOANINTEREST")<IArrayValue>();
export const setCarLoanAmount = createAction("loans/SETCARLOANAMOUNT")<IArrayValue>();
export const setCarLoanAmortization = createAction("loans/SETCARLOANAMORTIZATION")<IArrayValue>();
export const setCarLoanInterest = createAction("loans/SETCARLOANINTEREST")<IArrayValue>();
export const setCreditCardAmount = createAction("loans/SETCREDITAMOUNT")<IArrayValue>();
export const setCreditCardInterest = createAction("loans/SETCREDITINTEREST")<IArrayValue>();
export const saveLoan = createAction("kalp/SAVELOAN")();

export const setAccounts = createAction("savings/SETACCOUNTS")<number>();
export const setInvestments = createAction("savings/SETINVESTMENTS")<number>();
export const setAccountAmount = createAction("savings/SETACCOUNTAMOUNT")<number>();
export const setAccountSaving = createAction("savings/SETACCOUNTSAVING")<number>();
export const setInvestmentAmount = createAction("savings/SETINVESTMENTAMOUNT")<number>();
export const setInvestmentSaving = createAction("savings/SETINVESTMENTSAVING")<number>();
export const saveSavings = createAction("savings/SAVESAVINGS")();

export const setselectedViewMode = createAction("ui/SETSELECTEDVIEWMODE")<ViewMode>();
export const setSelectedSection = createAction("ui/SETSELECTEDSECTION")<{ section: Sections; hasData: boolean }>();
export const setSectionBasedOnScenario = createAction("ui/SETSECTIONBASEDONSCENARIO")<Sections>();
export const setNavigation = createAction("ui/SETNAVIGATION")<{ section: Sections; viewMode: ViewMode }>();

export const setInterestRateScenario = createAction("scenario/SETINTEREST")<{ fixationPeriod: string; interest: number }>();
export const resetInterestRateScenario = createAction("scenarios/RESETINTEREST")();

export const resetMortgageScenario = createAction("scenarios/RESETMORTGAGE")();
export const setMortgageScenario = createAction("scenarios/SETMORTGAGEVALUE")<{
    value: number;
    mortgage: number;
    mortgageInterestRate?: IMortgageInterestRateScenario;
}>();

export const setAmortizationScenario = createAction("scenario/SETAMORTIZATION")<{ value: number }>();
export const setAmortizationRequirementScenario = createAction("scenario/SETAMORTIZATIONREQUIREMENT")();
export const resetAmortizationScenario = createAction("scenario/RESETAMORTIZATION")();

export const setMaintenanceScenario = createAction("scenario/SETMAINTENANCE")<{ value: number }>();
export const resetMaintenanceScenario = createAction("scenario/RESETMAINTENANCE")();

export const setAdditionalChildScenario = createAction("scenario/SETADDITIONALCHILD")<{ value: Array<Child> }>();
export const resetAdditionalChildScenario = createAction("scenario/RESETADDITIONALCHILD")();

export const setInvestmentScenario = createAction("scenario/SETINVESTMENT")<{ monthly: number; interest: number }>();
export const resetInvestmentScenario = createAction("scenario/RESETINVESTMENT")();

export const setRenovationScenario = createAction("scenario/SETRENOVATION")<{ target: string; cost: number; year: number }>();
export const resetRenovationScenario = createAction("scenario/RESETRENOVATION")();
export const setWorkPartTimeScenario = createAction("scenario/SETWORKPARTTIME")<{ value: Array<Person> }>();
export const resetWorkPartTimeScenario = createAction("scenario/RESETWORKPARTTIME")();

export const setEvent = createAction("events/SETEVENT")<{ event: ITrackData }>();

export const resetScenarios = createAction("scenario/RESETALL")();

export const setRatingShown = createAction("events/SETRATINGSHOWN")();

export const rootReducer = createReducer({ forecastPeriod: 10 } as IRootState)
    .handleAction(setUserData, (state, action) => {
        const newState = Object.assign({}, state);
        newState.userEditingData = action.payload;
        newState.userData = action.payload;

        newState.result = calculateResult(newState.userData, newState.scenarioData, newState.calculationInterestRate);

        return newState;
    })
    .handleAction(setForecastPeriod, (state, action) => Object.assign({}, state, { forecastPeriod: action.payload }))
    .handleAction(setMortgageCalculationType, (state, action) => {
        const newState = ensure("household", state);
        if (!newState.userEditingData.household.downpayment) {
            const price = newState.userEditingData.household.price;
            const requiredDownPayment = Math.round(price * state.minDownpaymentRate);
            newState.userEditingData.household.downpayment = requiredDownPayment;
        }
        if (action.payload.type === MortgageCalculationType.move) {
            newState.userEditingData.household.downpayment = undefined;
        }
        newState.userEditingData.household.amortizationRequirementsAsDefault =
            action.payload.type === MortgageCalculationType.new || action.payload.useAmortizationRequirement;
        newState.userEditingData.household.calculationType = action.payload.type;

        return newState;
    })
    .handleAction(setHousingType, (state, action) => {
        const newState = ensure("household", state);
        const oldHousingType = newState.userEditingData.household.housingType;
        newState.userEditingData.household.housingType = action.payload;
        if (oldHousingType !== action.payload) {
            newState.userEditingData.household.maintenance = getMaintenance(action.payload);
            newState.userEditingData.household.fee = getFee(action.payload);
            newState.userEditingData.household.propertyTax = getPropertyTax(action.payload, state.userEditingData.household.price);
        }
        return newState;
    })
    .handleAction(setHousingValue, (state, action) => {
        const newState = ensure("household", state);
        const newPrice = action.payload;
        newState.userEditingData.household.price = newPrice;
        newState.userEditingData.household.propertyTax = getPropertyTax(newState.userEditingData.household.housingType, newPrice);

        const minCapital = Math.ceil(state.minDownpaymentRate * newPrice);
        if (state.userEditingData.household.downpayment !== minCapital) {
            newState.userEditingData.household.downpayment = minCapital;
        }

        return newState;
    })
    .handleAction(setHousingDownpayment, (state, action) => {
        const newState = ensure("household", state);
        newState.userEditingData.household.downpayment = action.payload;
        return newState;
    })
    .handleAction(setHousingAdults, (state, action) => {
        const newState = ensure("household", state);
        newState.userEditingData.household.adults = Array.from({ length: action.payload }, (_, index) => {
            return newState.userEditingData.household.adults && index < newState.userEditingData.household.adults.length
                ? Object.assign({}, newState.userEditingData.household.adults[index])
                : {
                      age: DEFAULT_ADULT_AGE,
                      income: DEFAULT_ADULT_INCOME,
                  };
        });
        return newState;
    })
    .handleAction(setAdultAge, (state, action) => {
        const newState = ensure("household", state);
        newState.userEditingData.household.adults[action.payload.index].age = action.payload.value;
        return newState;
    })
    .handleAction(setAdultIncome, (state, action) => {
        const newState = ensure("household", state);
        newState.userEditingData.household.adults[action.payload.index].income = action.payload.value;
        return newState;
    })
    .handleAction(setHousingMaintenance, (state, action) => {
        const newState = ensure("household", state);
        newState.userEditingData.household.maintenance = action.payload;
        return newState;
    })
    .handleAction(setHousingFee, (state, action) => {
        const newState = ensure("household", state);
        newState.userEditingData.household.fee = action.payload;
        return newState;
    })
    .handleAction(setHousingPropertyTax, (state, action) => {
        const newState = ensure("household", state);
        newState.userEditingData.household.propertyTax = action.payload;
        return newState;
    })
    .handleAction(setHousingEstimatedValue, (state, action) => {
        const newState = ensure("household", state);
        newState.userEditingData.household.estimatedValue = action.payload;
        newState.userEditingData.household.propertyTax = getPropertyTax(newState.userEditingData.household.housingType, action.payload);
        return newState;
    })
    .handleAction(setHousingMortgages, (state, action) => {
        const newState = ensure("household", state);
        newState.userEditingData.household.mortgages = Array.from({ length: action.payload }, (_, index) => {
            return newState.userEditingData.household.mortgages && index < newState.userEditingData.household.mortgages.length
                ? Object.assign({}, newState.userEditingData.household.mortgages[index])
                : {
                      amount: 2000000,
                      amortization: DEFAULT_MORTGAGE_AMORTIZATION,
                      interest: DEFAULT_MORTGAGE_INTEREST,
                  };
        });

        return newState;
    })
    .handleAction(setMortgageAmount, (state, action) => {
        const newState = ensure("household", state);
        newState.userEditingData.household.mortgages[action.payload.index].amount = action.payload.value;
        newState.userEditingData.household.mortgages[action.payload.index].amortization = Math.round(
            (action.payload.value * HIGH_AMORTIZATION_RATE_REQUIREMENT) / MONTHS_IN_YEAR,
        );
        return newState;
    })
    .handleAction(setMortgageInterest, (state, action) => {
        const newState = ensure("household", state);
        newState.userEditingData.household.mortgages[action.payload.index].interest = action.payload.value;
        return newState;
    })
    .handleAction(setMortgageAmortization, (state, action) => {
        const newState = ensure("household", state);
        newState.userEditingData.household.mortgages[action.payload.index].amortization = action.payload.value;
        return newState;
    })
    .handleAction(setAdditionalHousingMortgageAmount, (state, action) => {
        const newState = ensure("household", state);
        newState.userEditingData.kalp.additionalHousing.data.mortgage.amount = action.payload;
        return newState;
    })
    .handleAction(setAdditionalHousingMortgageAmortization, (state, action) => {
        const newState = ensure("household", state);
        newState.userEditingData.kalp.additionalHousing.data.mortgage.amortization = action.payload;
        return newState;
    })
    .handleAction(setAdditionalHousingFees, (state, action) => {
        const newState = ensure("household", state);
        newState.userEditingData.kalp.additionalHousing.data.fees = action.payload;
        return newState;
    })
    .handleAction(setAdditionalHousingPropertyTax, (state, action) => {
        const newState = ensure("household", state);
        newState.userEditingData.kalp.additionalHousing.data.propertyTax = action.payload;
        return newState;
    })
    .handleAction(setAdditionalHousingMaintenance, (state, action) => {
        const newState = ensure("household", state);
        newState.userEditingData.kalp.additionalHousing.data.maintenance = action.payload;
        return newState;
    })
    .handleAction(saveHousing, (state) => {
        const newState = Object.assign({}, state);
        newState.userData = Object.assign({}, newState.userData || {});
        newState.userData.household = Object.assign({}, state.userEditingData?.household);

        resetScenarioData(newState, Sections.Housing);

        newState.result = calculateResult(newState.userData, newState.scenarioData, newState.calculationInterestRate);

        return newState;
    })
    .handleAction(setKalpChildren, (state, action) => {
        const newState = ensure("kalp", state);
        newState.userEditingData.kalp.children = Array.from({ length: action.payload }, (_, index) => {
            return newState.userEditingData.kalp.children && index < newState.userEditingData.kalp.children.length
                ? Object.assign({}, newState.userEditingData.kalp.children[index])
                : {
                      age: 2,
                      householdRate: ChildHouseholdRate.FULL_TIME,
                  };
        });
        return newState;
    })
    .handleAction(setChildAge, (state, action) => {
        const newState = ensure("kalp", state);
        newState.userEditingData.kalp.children[action.payload.index].age = action.payload.value;
        return newState;
    })
    .handleAction(setChildHouseholdRate, (state, action) => {
        const newState = ensure("kalp", state);
        newState.userEditingData.kalp.children[action.payload.index].householdRate = action.payload.value;
        return newState;
    })
    .handleAction(addAdditionalHousing, (state) => {
        const newState = ensure("kalp", state);

        const additionalHousingInterestRate = Config.get("additionalHousingInterestRate") ?? DEFAULT_ADDITIONAL_HOUSING_INTEREST_RATE;

        newState.userEditingData.kalp.additionalHousing = {
            state: InputState.Specified,
            data: {
                mortgage: {
                    amortization: 0,
                    amount: 0,
                    interest: additionalHousingInterestRate,
                },
                propertyTax: DEFAULT_PROPERTY_TAX,
                fees: 0,
                maintenance: 0,
            },
        };
        return newState;
    })
    .handleAction(removeAdditionalHousing, (state) => {
        const newState = ensure("kalp", state);
        newState.userEditingData.kalp.additionalHousing = {
            state: InputState.Specified,
            data: undefined,
        };
        return newState;
    })
    .handleAction(setCarCost, (state, action) => {
        const newState = ensure("kalp", state);
        newState.userEditingData.kalp.carCost = action.payload;
        return newState;
    })
    .handleAction(saveKalp, (state) => {
        const newState = Object.assign({}, state);
        newState.userData = Object.assign({}, newState.userData || {});
        newState.userData.kalp = Object.assign({}, state.userEditingData?.kalp);

        resetScenarioData(newState, Sections.Kalp);

        newState.result = calculateResult(newState.userData, newState.scenarioData, newState.calculationInterestRate);

        return newState;
    })
    .handleAction(setStudentLoans, setStudentLoansFn)
    .handleAction(setPrivateLoans, setPrivateLoansFn)
    .handleAction(setCarLoans, setCarLoansFn)
    .handleAction(setStudentLoanAmount, setLoanAmountFn("studentLoans"))
    .handleAction(setStudentLoanAmortization, setLoanAmortizationFn("studentLoans"))
    .handleAction(setPrivateLoanAmount, setLoanAmountFn("privateLoans"))
    .handleAction(setPrivateLoanAmortization, setLoanAmortizationFn("privateLoans"))
    .handleAction(setPrivateLoanInterest, setLoanInterestFn("privateLoans"))
    .handleAction(setCarLoanAmount, setLoanAmountFn("carLoans"))
    .handleAction(setCarLoanAmortization, setLoanAmortizationFn("carLoans"))
    .handleAction(setCarLoanInterest, setLoanInterestFn("carLoans"))
    .handleAction(setCreditCardAmount, setLoanAmountFn("cardCredits"))
    .handleAction(setCreditCardInterest, setLoanInterestFn("cardCredits"))
    .handleAction(saveLoan, (state) => {
        const newState = Object.assign({}, state);
        newState.userData = Object.assign({}, newState.userData || {});
        newState.userData.loans = Object.assign({}, state.userEditingData?.loans);

        resetScenarioData(newState, Sections.Loans);

        newState.result = calculateResult(newState.userData, newState.scenarioData, newState.calculationInterestRate);

        return newState;
    })

    .handleAction(setAccountAmount, (state, action) => {
        const newState = ensure("savings", state);
        newState.userEditingData.savings.accountAmount = action.payload;
        return newState;
    })
    .handleAction(setAccountSaving, (state, action) => {
        const newState = ensure("savings", state);
        newState.userEditingData.savings.accountMonthlySavings = action.payload;
        return newState;
    })
    .handleAction(setInvestmentAmount, (state, action) => {
        const newState = ensure("savings", state);
        newState.userEditingData.savings.investmentsAmount = action.payload;
        return newState;
    })
    .handleAction(setInvestmentSaving, (state, action) => {
        const newState = ensure("savings", state);
        newState.userEditingData.savings.investmentsMonthlySavings = action.payload;
        return newState;
    })
    .handleAction(saveSavings, (state) => {
        const newState = Object.assign({}, state);
        newState.userData = Object.assign({}, newState.userData || {});
        newState.userData.savings = Object.assign({}, state.userEditingData?.savings);

        resetScenarioData(newState, Sections.Savings);

        newState.result = calculateResult(newState.userData, newState.scenarioData, newState.calculationInterestRate);
        return newState;
    })

    .handleAction(setselectedViewMode, (state, action) => {
        const viewMode = action.payload;

        return {
            ...state,
            selectedViewMode: viewMode,
            sectionCollapsed: false,
        };
    })

    .handleAction(setSelectedSection, (state, action) => {
        const { section, hasData } = action.payload;

        if (!state.userData?.household) {
            return {
                ...state,
                selectedViewMode: ViewMode.Input,
                selectedSection: Sections.Housing,
                sectionCollapsed: false,
            };
        }

        if (section === state.selectedSection) {
            if (state.selectedViewMode === ViewMode.Input) {
                return state;
            }

            return {
                ...state,
                sectionCollapsed: !state.sectionCollapsed,
            };
        }

        const userEditingData = deepClone(ensureDefaults(state.userData));
        if (!hasData) {
            return {
                ...state,
                userEditingData,
                selectedSection: section,
                selectedViewMode: ViewMode.Input,
            };
        }

        return {
            ...state,
            userEditingData,
            selectedSection: section,
            selectedViewMode: ViewMode.Result,
            sectionCollapsed: state.selectedViewMode === ViewMode.Input,
        };
    })

    .handleAction(setSectionBasedOnScenario, (state, action) => {
        const selectedSection = action.payload;
        const sectionCollapsed = false;

        if (state.selectedViewMode !== ViewMode.Result) {
            return state;
        }
        return { ...state, selectedSection, sectionCollapsed };
    })

    .handleAction(setNavigation, (state, action) => {
        const { viewMode, section } = action.payload;
        if (viewMode === ViewMode.Input && section !== Sections.Housing && !state.userData?.household) {
            return {
                ...state,
                selectedViewMode: viewMode,
                selectedSection: Sections.Housing,
                sectionCollapsed: false,
            };
        }

        return {
            ...state,
            selectedViewMode: viewMode,
            selectedSection: section,
            sectionCollapsed: false,
        };
    })
    .handleAction(setInterestRateScenario, (state, action) => {
        const { fixationPeriod, interest } = action.payload;
        const newState = Object.assign({}, state);
        if (!newState.scenarioData) {
            newState.scenarioData = {};
        }

        newState.scenarioData.mortgageInterestRate = {
            fixationPeriod,
            interest,
        };

        if (newState.userData) {
            newState.result = calculateResult(newState.userData, newState.scenarioData, newState.calculationInterestRate);
        }

        return newState;
    })
    .handleAction(resetInterestRateScenario, (state) => {
        return disableInterestRateScenario(state);
    })
    .handleAction(resetMortgageScenario, (state) => {
        const newState = disableInterestRateScenario(state);

        if (newState.scenarioData) {
            newState.scenarioData.mortgageData = null;
        }

        if (getMortgage(newState.userData) <= 0) {
            newState.scenarioData.mortgageAmortization = null;
        }

        if (newState.userData) {
            newState.result = calculateResult(newState.userData, newState.scenarioData, newState.calculationInterestRate);
        }

        return newState;
    })
    .handleAction(setMortgageScenario, (state, action) => {
        const { value, mortgage, mortgageInterestRate } = action.payload;
        const newState = disableInterestRateScenario(state);
        if (!newState.scenarioData) {
            newState.scenarioData = {};
        }
        const maxLTVRatio = 1 - state.minDownpaymentRate;
        const cappedMortgage = Math.min(Math.round(value * maxLTVRatio), mortgage);

        newState.scenarioData.mortgageData = {
            housingValue: value,
            mortgage: cappedMortgage,
        };
        newState.scenarioData.mortgageInterestRate = mortgageInterestRate;

        if (mortgage <= 0) {
            newState.scenarioData.mortgageAmortization = null;
        }

        if (newState.userData) {
            newState.result = calculateResult(newState.userData, newState.scenarioData, newState.calculationInterestRate);
        }

        return newState;
    })
    .handleAction(setAmortizationScenario, (state, action) => {
        const { value } = action.payload;
        const newState = Object.assign({}, state);
        if (!newState.scenarioData) {
            newState.scenarioData = {};
        }

        newState.scenarioData.mortgageAmortization = {
            amortizationValue: value,
            usingRequirements: false,
        };

        if (newState.userData) {
            newState.result = calculateResult(newState.userData, newState.scenarioData, newState.calculationInterestRate);
        }

        return newState;
    })
    .handleAction(setAmortizationRequirementScenario, (state) => {
        const newState = Object.assign({}, state);
        if (!newState.scenarioData) {
            newState.scenarioData = {};
        }

        newState.scenarioData.mortgageAmortization = {
            amortizationValue: undefined,
            usingRequirements: true,
        };

        if (newState.userData) {
            newState.result = calculateResult(newState.userData, newState.scenarioData, newState.calculationInterestRate);
        }

        return newState;
    })
    .handleAction(resetAmortizationScenario, (state) => {
        const newState = Object.assign({}, state);

        if (newState.scenarioData) {
            newState.scenarioData.mortgageAmortization = undefined;
        }

        if (newState.userData) {
            newState.result = calculateResult(newState.userData, newState.scenarioData, newState.calculationInterestRate);
        }

        return newState;
    })
    .handleAction(setMaintenanceScenario, (state, action) => {
        const { value } = action.payload;
        const newState = Object.assign({}, state);
        if (!newState.scenarioData) {
            newState.scenarioData = {};
        }

        newState.scenarioData.mortgageMaintenance = value;

        if (newState.userData) {
            newState.result = calculateResult(newState.userData, newState.scenarioData, newState.calculationInterestRate);
        }

        return newState;
    })
    .handleAction(resetMaintenanceScenario, (state) => {
        const newState = Object.assign({}, state);

        if (newState.scenarioData) {
            newState.scenarioData.mortgageMaintenance = null;
        }

        if (newState.userData) {
            newState.result = calculateResult(newState.userData, newState.scenarioData, newState.calculationInterestRate);
        }

        return newState;
    })
    .handleAction(setAdditionalChildScenario, (state, action) => {
        const { value } = action.payload;
        const newState = Object.assign({}, state);

        if (!newState.scenarioData) {
            newState.scenarioData = {};
        }
        newState.scenarioData.additionalChild = [...value];

        if (newState.userData) {
            newState.result = calculateResult(newState.userData, newState.scenarioData, newState.calculationInterestRate);
        }

        return newState;
    })
    .handleAction(resetAdditionalChildScenario, (state) => {
        const newState = Object.assign({}, state);

        if (newState.scenarioData) {
            newState.scenarioData.additionalChild = null;
        }

        if (newState.userData) {
            newState.result = calculateResult(newState.userData, newState.scenarioData, newState.calculationInterestRate);
        }

        return newState;
    })
    .handleAction(setInvestmentScenario, (state, action) => {
        const { monthly, interest } = action.payload;
        const newState = Object.assign({}, state);
        if (!newState.scenarioData) {
            newState.scenarioData = {};
        }

        newState.scenarioData.investmentMonthly = monthly;
        newState.scenarioData.investmentInterest = interest;

        if (newState.userData) {
            newState.result = calculateResult(newState.userData, newState.scenarioData, newState.calculationInterestRate);
        }

        return newState;
    })
    .handleAction(resetInvestmentScenario, (state) => {
        const newState = Object.assign({}, state);

        if (newState.scenarioData) {
            newState.scenarioData.investmentMonthly = null;
            newState.scenarioData.investmentInterest = null;
        }

        if (newState.userData) {
            newState.result = calculateResult(newState.userData, newState.scenarioData, newState.calculationInterestRate);
        }

        return newState;
    })
    .handleAction(setRenovationScenario, (state, action) => {
        const { target, cost, year } = action.payload;
        const newState = Object.assign({}, state);
        if (!newState.scenarioData) {
            newState.scenarioData = {};
        }

        newState.scenarioData.renovationTarget = target;
        newState.scenarioData.renovationCost = cost;
        newState.scenarioData.renovationYear = year;

        if (newState.userData) {
            newState.result = calculateResult(newState.userData, newState.scenarioData, newState.calculationInterestRate);
        }

        return newState;
    })
    .handleAction(resetRenovationScenario, (state) => {
        const newState = Object.assign({}, state);

        if (newState.scenarioData) {
            newState.scenarioData.renovationCost = null;
            newState.scenarioData.renovationTarget = null;
            newState.scenarioData.renovationYear = null;
        }

        if (newState.userData) {
            newState.result = calculateResult(newState.userData, newState.scenarioData, newState.calculationInterestRate);
        }

        return newState;
    })
    .handleAction(setWorkPartTimeScenario, (state, action) => {
        const { value } = action.payload;
        const newState = Object.assign({}, state);
        if (!newState.scenarioData) {
            newState.scenarioData = {};
        }

        newState.scenarioData.adultsWorkPartTime = value;

        if (newState.userData) {
            newState.result = calculateResult(newState.userData, newState.scenarioData, newState.calculationInterestRate);
        }

        return newState;
    })
    .handleAction(resetWorkPartTimeScenario, (state) => {
        const newState = Object.assign({}, state);

        if (newState.scenarioData) {
            newState.scenarioData.adultsWorkPartTime = null;
        }

        if (newState.userData) {
            newState.result = calculateResult(newState.userData, newState.scenarioData, newState.calculationInterestRate);
        }

        return newState;
    })
    .handleAction(resetScenarios, (state) => {
        const newState = Object.assign({}, state);

        resetScenarioData(newState);

        if (newState.userData) {
            newState.result = calculateResult(newState.userData, newState.scenarioData, newState.calculationInterestRate);
        }

        return newState;
    })
    .handleAction(setEvent, (state, action) => {
        const { event } = action.payload;
        const newState = Object.assign({}, state);

        if (!Array.isArray(newState.events) || !newState.events.length) {
            newState.events = [];
        }

        if (!hasOccured(newState.events, event)) {
            newState.events.push(event);
        }

        return newState;
    })
    .handleAction(setRatingShown, (state) => {
        const newState = Object.assign({}, state);

        newState.ratingShown = true;

        return newState;
    });

// NOTE: this is called every time the saves new input data as this could generate new offers from the bank
function disableInterestRateScenario(state) {
    const newState = Object.assign({}, state);

    if (newState.scenarioData) {
        newState.scenarioData.mortgageInterestRate = null;
    }

    if (newState.userData) {
        newState.result = calculateResult(newState.userData, newState.scenarioData, newState.calculationInterestRate);
    }

    return newState;
}
