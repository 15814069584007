import * as React from "react";

import classNames from "classnames";
import transitionEnd from "dom-helpers/transitionEnd";
import { withTranslation, type WithTranslation } from "react-i18next";
import { AccessibleButton } from "./accessibility/accessible-components";
import { AnimationTransition } from "./wrappers/animations";
export interface InputWizardProps extends WithTranslation {
    onFinalStep?: () => void;
    onFirstStep?: () => void;
    children: React.ReactNode;
}

interface InputWizardState {
    currentStep: number;
    steps: number;
    direction: string;
    validated: { [index: number]: boolean };
    nextButtonDisabled: { [index: number]: boolean };
}

class InputWizardComponent extends React.Component<InputWizardProps, InputWizardState> {
    constructor(props) {
        super(props);

        this.state = {
            currentStep: 0,
            steps: 0,
            direction: "next",
            validated: {},
            nextButtonDisabled: {},
        };
    }

    componentDidMount() {
        const steps = React.Children.toArray(this.props.children).filter((child) => child !== null && child !== undefined).length;
        this.setState({
            currentStep: this.state.currentStep,
            steps,
            validated: {},
        });
    }

    nextStep() {
        if (this.state.currentStep === 0 && this.props.onFirstStep) {
            this.props.onFirstStep();
        }

        if (this.state.currentStep === this.state.steps - 1) {
            if (this.props.onFinalStep) {
                this.props.onFinalStep();
            }
        } else {
            this.setState({
                direction: "next",
                currentStep: Math.min(this.state.currentStep + 1, this.state.steps - 1),
            });
        }
    }

    prevStep() {
        this.setState({
            direction: "prev",
            currentStep: Math.max(this.state.currentStep - 1, 0),
        });
    }

    onValidate(index: number, result: boolean) {
        if (this.state.validated[index] !== result) {
            const validated = Object.assign({}, this.state.validated);
            validated[index] = result;
            this.setState({ validated });
        }
    }

    nextButtonDisabled(index: number, result: boolean) {
        if (this.state.nextButtonDisabled[index] !== result) {
            const nextButtonDisabled = Object.assign({}, this.state.nextButtonDisabled);
            nextButtonDisabled[index] = result;
            this.setState({ nextButtonDisabled });
        }
    }

    componentDidUpdate() {
        const childCount = React.Children.toArray(this.props.children).filter(
            (child: React.ReactElement) => child !== null && child !== undefined && !child.props.automatic,
        ).length;
        if (childCount !== this.state.steps) {
            this.setState({ steps: childCount });
        }
    }

    isValid() {
        return !!this.state.validated[this.state.currentStep];
    }

    isNextButtonDisabled() {
        return !!this.state.nextButtonDisabled[this.state.currentStep];
    }

    handleEnter(node) {
        // To make the transition work properly, see <https://semisignal.com/triggering-reflow-for-css3-transitions/>
        triggerBrowserReflow(node);
    }

    render() {
        const { t } = this.props;
        const percent = Math.max(0, Math.min(((this.state.currentStep + 1) / this.state.steps) * 100, 100));

        const orderClassName = `slide-${this.state.direction}`;
        const slideDirection = this.state.direction === "next" ? "left" : "right";
        const directionalClassName = `slide-${slideDirection}`;

        const children = React.Children.map(this.props.children, (child: React.ReactElement, index: number) => {
            if (child) {
                const isActive = index === this.state.currentStep;

                const clonedElement = (
                    <AnimationTransition in={isActive} onEnter={isActive ? this.handleEnter : undefined} addEndListener={transitionEnd}>
                        {(status) => (
                            <div
                                className={classNames(
                                    "indata-slide",
                                    isActive && status !== "entered" && orderClassName,
                                    (status === "entered" || status === "exiting") && "active",
                                    (status === "entering" || status === "exiting") && directionalClassName,
                                )}
                            >
                                {React.cloneElement(child, {
                                    onValidate: this.onValidate.bind(this, index),
                                    nextStep: this.nextStep.bind(this),
                                    currentStep: this.state.currentStep,
                                    automatic: child.props.automatic,
                                    handleNextButtonState: this.nextButtonDisabled.bind(this, index),
                                })}
                            </div>
                        )}
                    </AnimationTransition>
                );
                return clonedElement;
            }
            return null;
        }).filter((child) => child !== null);

        return (
            <div className="input-wizard" aria-live="polite">
                <div className="wizard-progress-bar">
                    <div
                        className="wizard-progress"
                        ref={(node) => {
                            if (node) {
                                node.style.setProperty("width", `${percent}%`, "important");
                            }
                        }}
                    />
                </div>
                <div className="slides-wrapper">{children}</div>
                <div className="input-progress-buttons">
                    {this.state.currentStep > 0 ? (
                        <AccessibleButton
                            aria-label={t("aria:previous-step")}
                            className="wizard-back outline"
                            data-cy="wizard-previous-step"
                            onClick={this.prevStep.bind(this)}
                        >
                            {t("common:wizard-back")}
                        </AccessibleButton>
                    ) : null}
                    {this.isValid() ? (
                        <AccessibleButton
                            aria-label={t("aria:next-step")}
                            className={classNames("wizard-next", {
                                disabled: this.isNextButtonDisabled(),
                                outline: this.isNextButtonDisabled(),
                            })}
                            data-cy={this.state.currentStep === this.state.steps - 1 ? "go-to-result" : "wizard-next-step"}
                            onClick={this.nextStep.bind(this)}
                            disabled={this.isNextButtonDisabled()}
                        >
                            {t("common:wizard-next")}
                        </AccessibleButton>
                    ) : null}
                </div>
            </div>
        );
    }
}

export default withTranslation()(InputWizardComponent);

function triggerBrowserReflow(node: HTMLElement): void {
    node.offsetHeight;
}
