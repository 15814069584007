import * as React from "react";
import * as d3 from "d3";
import { type WithTranslation, withTranslation } from "react-i18next";

import type { IKalpResult } from "../../models/result";
import { linear } from "../../models/linear";
import { formatYTickLabel, formatYear } from "../../utility/chart";
import { Chart, type IInfoTooltipProps } from "./chart";
import { Area } from "./area";
import type { TooltipEvent } from "../../utility/analytics";

export interface IKalpChartProps extends WithTranslation {
    result: IKalpResult;
    period: number;
    tooltips?: Array<IInfoTooltipProps>;
    onEngagement: (label: TooltipEvent) => void;
}

class KalpChart extends React.Component<IKalpChartProps> {
    render() {
        const { result, t, onEngagement } = this.props;

        if (!result) {
            return;
        }

        const chartProps = {
            className: "insights kalp",
            id: "kalp",
            xTicks: 3,
            yTicks: 5,
            labels: ["kalp-summary-positive", "kalp-summary-negative"],
            summary: result.summary,
            infoTooltips: this.props.tooltips,
        };

        let points = linear(result.summary.scenarioFuture || result.summary.future, "kalp-colors");
        points = points.map((v) => {
            return { ...v, y0: 0 };
        });

        const total = [];
        let prev = points[0];
        let current = [prev];
        for (let i = 1; i < points.length; ++i) {
            if ((points[i].y !== prev.y && points[i].y <= 0 && prev.y > 0) || (points[i].y !== prev.y && points[i].y >= 0 && prev.y < 0)) {
                if (points[i].y === 0) {
                    current.push(points[i]);
                    total.push(current);
                    current = [];
                    current.push(points[i]);
                    prev = points[i];
                    continue;
                }
                const diff = Math.abs(points[i].y - prev.y);
                const midPoint = {
                    ...points[i],
                    y: 0,
                    x: prev.x + Math.abs(prev.y) / diff,
                };
                current.push(midPoint);
                total.push(current);
                current = [];
                current.push(midPoint);
            }

            current.push(points[i]);
            prev = points[i];
        }
        if (current.length > 0 && points[points.length - 1].y !== 0) {
            total.push(current);
        }

        const areas = total.map((v, i) => {
            let className = "";
            const min = d3.min<number>(v.map((x) => x.y));
            if (min < 0) {
                className = "negative";
            }
            // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
            return <Area key={`kalp-area-${i}`} label="kalp-summary" points={v} className={className} onEngagement={onEngagement} />;
        });

        return (
            <Chart
                {...chartProps}
                xTickFormat={(x) => formatYear(x, t)}
                yTickFormat={(y) => formatYTickLabel(y)}
                onEngagement={onEngagement}
                totalAreaOnTop={true}
            >
                {areas}
            </Chart>
        );
    }
}

export default withTranslation()(KalpChart);
