import type { IInterestDiscountLevel } from "../modules/tips/interest-discount-new-loan";
import { type IRootState, setEvent } from "../reducers/root-reducer";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTracking } from "react-tracking";
import classNames from "classnames";
import SlidesComponent from "./slides-component";
import { ModuleStore } from "../utility/module-store";
import { useTranslation } from "react-i18next";
import { TipsActions, trackTips } from "../utility/analytics";
import { hasOccured } from "../utility/one-time-events";
import { scrollToResult } from "./scenarios";
import { AnimationSwitch, CSSAnimation } from "./wrappers/animations";

export interface ITipsConfiguration {
    id: string;
    configuration?: { discounts: Array<IInterestDiscountLevel> };
}

export interface ITipsComponentProps {
    availableModules: Array<string | ITipsConfiguration>;
}

export default function TipsComponent(props: ITipsComponentProps) {
    const rootState = useSelector((state: IRootState) => state);
    const { t } = useTranslation();
    const [activeTipsId, setActiveTipsId] = useState(null);
    const [animationState, setAnimationState] = useState(undefined);
    const dispatch = useDispatch();
    const tracking = useTracking();

    const isTipsAvailable = (tips: ITipsConfiguration) => {
        const module = ModuleStore.getTipsModule(tips.id);
        return module?.activationFunction(rootState, tips.configuration);
    };

    const trackUsage = (label, action) => {
        const event = trackTips(action, label);
        if (!hasOccured(rootState.events, event)) {
            tracking.trackEvent(event);
            dispatch(setEvent({ event }));
        }
    };

    const selectComponent = (tips: ITipsConfiguration) => {
        const module = ModuleStore.getTipsModule(tips.id);
        if (!module) {
            return null;
        }

        return React.createElement(module.component, {
            rootState,
            configuration: tips.configuration,
            onActivated: () => {
                setAnimationState((Math.random() + 1).toString(36).substring(7));
                setTimeout(scrollToResult, 300);
                trackUsage(tips.id, TipsActions.ACTIVATED);
            },
        });
    };

    const filteredTips = (props.availableModules ?? [])
        .map((module) => {
            if (typeof module === "string") {
                return { id: module };
            }
            return module;
        })
        .filter((module) => {
            if (isTipsAvailable(module)) {
                return module;
            }
        });

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (activeTipsId) {
            trackUsage(activeTipsId, TipsActions.TIPS_CHANGED);
        }
    }, [activeTipsId]);

    useEffect(() => {
        for (const tips of filteredTips) {
            trackUsage(tips.id, TipsActions.APPLICABLE);
        }
    });

    return (
        <AnimationSwitch>
            {!filteredTips || filteredTips.length <= 0 ? (
                <CSSAnimation key="notips" timeout={0}>
                    <div />
                </CSSAnimation>
            ) : (
                <CSSAnimation key="tips" timeout={0}>
                    <div className={classNames("tips-container")} tabIndex={-1}>
                        <div className="tips-header">
                            <h3>{activeTipsId ? t(`tips:title-${activeTipsId}`) : ""}</h3>
                        </div>
                        <div className="tips-body">
                            <SlidesComponent
                                onSlideChange={(label) => {
                                    setActiveTipsId(label);
                                }}
                                animationState={animationState}
                            >
                                {filteredTips.map((module) => {
                                    return <div key={module.id}>{selectComponent(module)}</div>;
                                })}
                            </SlidesComponent>
                        </div>
                    </div>
                </CSSAnimation>
            )}
        </AnimationSwitch>
    );
}
