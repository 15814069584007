import * as React from "react";
import InputWizardComponent from "../input-wizard";
import AccountStepComponent from "../steps/savings/savings-account-step";
import InvestmentStepComponent from "../steps/savings/savings-investment-step";
import { useDispatch } from "react-redux";
import { useTracking } from "react-tracking";
import { trackUserInput, UserInputActions, StepNames } from "../../utility/analytics";
import { saveSavings, setNavigation, ViewMode, Sections } from "../../reducers/root-reducer";

export default function SavingsWizard() {
    const dispatch = useDispatch();
    const tracking = useTracking();

    function goToResult() {
        dispatch(saveSavings());
        tracking.trackEvent(trackUserInput(UserInputActions.STEP_COMPLETED, StepNames.SAVINGS));
        dispatch(
            setNavigation({
                viewMode: ViewMode.Result,
                section: Sections.Savings,
            }),
        );
    }

    function emitStepStarted() {
        tracking.trackEvent(trackUserInput(UserInputActions.STEP_STARTED, StepNames.SAVINGS));
    }

    return (
        <InputWizardComponent onFinalStep={goToResult} onFirstStep={emitStepStarted}>
            <AccountStepComponent />
            <InvestmentStepComponent />
        </InputWizardComponent>
    );
}
