import * as React from "react";
import IndataStepComponent from "../../indata-step";
import { useSelector, useDispatch } from "react-redux";
import { type IRootState, setMortgageAmortization } from "../../../reducers/root-reducer";
import NumberComponent from "../../form-controls/number-component";
import { useTranslation } from "react-i18next";
import DisclaimerComponent from "../../typography/disclaimer-component";
import OverlineComponent from "../../typography/overline-component";
import { getAmortizationRatio } from "../../../functions/household";

export default function HousingMorgageAmortizationStepComponent(props) {
    const { amortization, mortgages } = useSelector((state: IRootState) => {
        return {
            amortization: state.userEditingData?.household?.mortgages[props.index].amortization || 0,
            mortgages: state.userEditingData?.household?.mortgages[props.index].amount || 0,
        };
    });

    const dispatch = useDispatch();
    const { t } = useTranslation();

    function onChangeAmortization(value: number) {
        props.onValidate(true);
        dispatch(setMortgageAmortization({ index: props.index, value: value }));
    }

    React.useEffect(() => {
        props.onValidate(true);
    });

    return (
        <IndataStepComponent active={props.active}>
            <OverlineComponent>{t("housing:mortgage-enumeration", { index: props.index + 1 })}</OverlineComponent>
            <NumberComponent
                name={`housing_mortgage_${props.index}_amortization`}
                unit={t("common:sek-month")}
                onChange={onChangeAmortization}
                min={0}
                step={100}
                defaultMax={20000}
                label={t("housing:input-mortgage-amortization")}
                detailLabel={`${getAmortizationRatio(amortization, mortgages)} %`}
                value={amortization}
            />
            <DisclaimerComponent>{t("housing:input-amortization-disclaimer")}</DisclaimerComponent>
        </IndataStepComponent>
    );
}
