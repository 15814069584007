import * as React from "react";
import IndataStepComponent from "../../indata-step";
import { useSelector, useDispatch } from "react-redux";
import { type IRootState, setInvestmentAmount, setInvestmentSaving } from "../../../reducers/root-reducer";
import NumberComponent from "../../form-controls/number-component";
import { useTranslation } from "react-i18next";
import OverlineComponent from "../../typography/overline-component";

export default function InvestmentStepComponent(props) {
    const { amount, saving } = useSelector((state: IRootState) => {
        return {
            amount: state.userEditingData.savings.investmentsAmount,
            saving: state.userEditingData.savings.investmentsMonthlySavings,
        };
    });

    const dispatch = useDispatch();
    const { t } = useTranslation();

    function onChangeFirst(value: number) {
        props.onValidate(true);
        dispatch(setInvestmentAmount(value));
    }

    function onChangeSecond(value: number) {
        props.onValidate(true);
        dispatch(setInvestmentSaving(value));
    }

    React.useEffect(() => {
        props.onValidate(true);
    });

    return (
        <IndataStepComponent active={props.active}>
            <OverlineComponent>{t("savings:input-investments")}</OverlineComponent>
            <NumberComponent
                name={"savings_investment_amount"}
                unit={t("common:sek")}
                onChange={onChangeFirst}
                min={0}
                step={25000}
                defaultMax={3000000}
                label={t("savings:input-investment-amount")}
                value={amount}
            />
            <NumberComponent
                name={"savings_investment_saving"}
                unit={t("common:sek")}
                onChange={onChangeSecond}
                min={0}
                step={100}
                defaultMax={10000}
                label={t("savings:input-investment-saving")}
                value={saving}
            />
        </IndataStepComponent>
    );
}
