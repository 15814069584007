function runNewtonRaphson(fx: (x: number) => number, fdx: (x: number) => number, guess: number) {
    const precision = 4;
    const errorLimit = 10 ** (-1 * precision);
    let previousValue = 0;

    let newGuess: number;
    do {
        const oldGuess = Number(guess);
        previousValue = Number(oldGuess);
        newGuess = previousValue - Number(fx(oldGuess)) / Number(fdx(oldGuess));
    } while (Math.abs(guess - previousValue) > errorLimit);

    return newGuess;
}

export default class EffectiveInterestCalculator {
    /**
     * Calculates the effective yearly interest based on the nominal interest rate.
     *
     * @param {number} interest The nominal interest to calculate effective interest from
     */
    static withInterest(interest: number) {
        // This simplified formula works for scenarios without fees
        return (1 + interest / 12) ** 12 - 1;
    }

    /**
     * Calculates the effective interest of a mortgage based on monthly payments
     * including fees. If there is an arrangement fee that is payed initially at
     * month 0 it should be deducted from the principal amount.
     *
     * See https://en.wikipedia.org/wiki/Internal_rate_of_return for more information
     *
     * @param {number} principal The initial mortgage amount
     * @param {number} payments A list of monthly payments
     * @param {number} guess The initial guess for the interest, for example the nominal interest
     */
    static withSpecifiedMonthlyPayments(principal: number, payments: Array<number>, guess: number) {
        // Add the principal value as a negative payment at month 0
        const values = [-1 * principal].concat(payments);

        // Sums the active credit at all points in time, i.e
        // principal, principal - firstPayment, principal - firstTwoPayments etc.
        const fx = (x: number) => {
            let sum = 0;

            values.forEach((value, idx) => {
                sum += value * (1 + x) ** (-idx / 12);
            });

            return sum;
        };

        // Sums all credit payments (principal at index 0 will not be counted here)
        const fdx = (x: number) => {
            let sum = 0;

            values.forEach((value, idx) => {
                sum += (-idx / 12) * value * (1 + x) ** (-idx / 12 - 1);
            });

            return sum;
        };

        return runNewtonRaphson(fx, fdx, guess);
    }

    /**
     * Calculates the effective interest of mortgage using flat amortization, including fees.
     *
     * @param {number} principal The initial amount of the mortgage
     * @param {number} numberOfMonths The number of months the mortgage is payed over
     * @param {number} interest The yearly interest of the mortgage
     * @param {number} arrangementFee The arrangement fee for setting up the mortgage
     * @param {number} administrationFee The monthly administration fee for the mortgage
     */
    static withFlatAmortization(principal: number, numberOfMonths: number, interest: number, arrangementFee: number, administrationFee: number) {
        const payment = principal / numberOfMonths;
        let p = principal;

        const payments = [];
        for (let i = 0; i < numberOfMonths; ++i) {
            const periodicInterest = (p * interest) / 12;
            p -= payment;
            payments.push(payment + periodicInterest + administrationFee);
        }

        return EffectiveInterestCalculator.withSpecifiedMonthlyPayments(principal - arrangementFee, payments, interest);
    }

    /**
     * Calculates the effective interest of mortgage using amortization strategy, i.e equal monthly payments, including fees.
     *
     * @param {number} principal The initial amount of the mortgage
     * @param {number} numberOfMonths The number of months the mortgage is payed over
     * @param {number} interest The yearly interest of the mortgage
     * @param {number} arrangementFee The arrangement fee for setting up the mortgage
     * @param {number} administrationFee The monthly administration fee for the mortgage
     */
    static withAmortizationLoan(principal: number, numberOfMonths: number, interest: number, arrangementFee: number, administrationFee: number) {
        const monthlyInterest = interest / 12;
        const monthlyPayment =
            (principal * monthlyInterest * (1 + monthlyInterest) ** numberOfMonths) / ((1 + monthlyInterest) ** numberOfMonths - 1);

        let p = principal;

        const payments = [];
        for (let i = 0; i < numberOfMonths; ++i) {
            const periodicInterest = (p * interest) / 12;
            p -= monthlyPayment - periodicInterest;
            payments.push(monthlyPayment + administrationFee);
        }

        return EffectiveInterestCalculator.withSpecifiedMonthlyPayments(principal - arrangementFee, payments, interest);
    }
}
