import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { type IRootState, Sections, setRatingShown } from "../../reducers/root-reducer";
import Config from "../../config";
import classNames from "classnames";
import { AccessibleButton } from "../accessibility/accessible-components";

export const Rating = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [visible, setVisible] = useState(false);
    const [value, setValue] = useState(null);
    const [feedback, setFeedback] = useState(null);
    const [show, setShow] = useState(false);

    const { result, ratingShown } = useSelector((state: IRootState) => {
        return { ratingShown: state.ratingShown, result: state.result };
    });

    useEffect(() => {
        if (!show) {
            return;
        }

        if (!ratingShown) {
            setTimeout(() => setVisible(true), 15000);
            dispatch(setRatingShown());
        }
    }, [show, ratingShown, dispatch]);

    const hasData = !!result?.[Sections.Housing];
    if (!hasData) return null;

    const onRating = Config.get("onRating");
    if (typeof onRating !== "function") return null;

    if (!show) setShow(true);

    const validate = () => {
        return value !== null && value > 0 && value < 6;
    };

    const send = () => {
        if (!validate()) return;

        onRating(value, feedback);
        setVisible(false);
    };

    if (!visible) return null;

    return (
        <div className="rating-overlay">
            <div className="rating-modal">
                <form className="rating-content" onSubmit={(e) => e.preventDefault()}>
                    <h3>{t("rating:header")}</h3>
                    <p>{t("rating:subheader")}</p>
                    {/* biome-ignore lint/complexity/useLiteralKeys: <explanation> */}
                    <div className="rating-component" onChange={(event) => setValue(event.target["value"])}>
                        <input id="rating5" type="radio" name="rating" value="5" />
                        <label htmlFor="rating5">
                            <i className="icon icon-star-fill" />
                        </label>

                        <input id="rating4" type="radio" name="rating" value="4" />
                        <label htmlFor="rating4">
                            <i className="icon icon-star-fill" />
                        </label>

                        <input id="rating3" type="radio" name="rating" value="3" />
                        <label htmlFor="rating3">
                            <i className="icon icon-star-fill" />
                        </label>

                        <input id="rating2" type="radio" name="rating" value="2" />
                        <label htmlFor="rating2">
                            <i className="icon icon-star-fill" />
                        </label>

                        <input id="rating1" type="radio" name="rating" value="1" />
                        <label htmlFor="rating1">
                            <i className="icon icon-star-fill" />
                        </label>
                    </div>
                    <p>{t("rating:question")}</p>
                    <textarea placeholder={t("rating:feedback-placeholder")} onChange={(event) => setFeedback(event.target.value)} />
                    <AccessibleButton
                        aria-label={t("aria:previous-step")}
                        className={classNames({ disabled: !validate() })}
                        onClick={send}
                        disabled={!validate}
                    >
                        {t("common:send")}
                    </AccessibleButton>
                </form>
                <div className="close-button" role="button" tabIndex={0} onClick={() => setVisible(false)} onKeyPress={() => setVisible(false)}>
                    <i className="icon-cross" />
                </div>
            </div>
        </div>
    );
};
