import React from "react";
import DisclaimerComponent from "../typography/disclaimer-component";

export interface DisclaimerOfferConfig {
    name: string;
    disclaimer: string;
}
export interface DisclaimerOfferProps {
    config: DisclaimerOfferConfig;
}

export default function DisclaimerOfferComponent(props: DisclaimerOfferProps) {
    const { config } = props;

    return (
        <React.Fragment>
            <DisclaimerComponent>{config.disclaimer}</DisclaimerComponent>
        </React.Fragment>
    );
}
