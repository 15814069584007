import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useTracking } from "react-tracking";
import classNames from "classnames";
import { Sections, type IRootState, setEvent } from "../reducers/root-reducer";
import { HousingChart } from "./chart/housing-chart";
import KalpChart from "./chart/kalp-chart";
import { SavingsChart } from "./chart/savings-chart";
import { LoansChart } from "./chart/loans-chart";
import ContainerHeader from "./container-component";
import type { ILoansResult, ISavingsResult } from "../models/result";
import { resultIsAvailable, resultIsEmpty } from "../utility/result-builder";
import { getInvestmentInterest, getSavingsInterest } from "../functions/savings";
import DisclaimerComponent from "./typography/disclaimer-component";
import { trackEngagement } from "../utility/analytics";
import { hasOccured } from "../utility/one-time-events";
import { AnimationSwitch, CSSAnimation } from "./wrappers/animations";
import { formatLocalAmount } from "../utility/number-formatter";
import Config from "../config";

const Housing = () => {
    const { t } = useTranslation();
    const tracking = useTracking();
    const dispatch = useDispatch();
    const { result, events } = useSelector((state: IRootState) => {
        return {
            result: state.result,
            events: state.events,
        };
    });

    const trackUsage = (label) => {
        const event = trackEngagement(label);

        if (!hasOccured(events, event)) {
            tracking.trackEvent(event);
            dispatch(setEvent({ event }));
        }
    };

    const data = result?.housing;
    const infoTooltips = result?.housingTooltips;

    return (
        <div
            className={classNames("container insights-container housing-insight", {
                disabled: !data,
            })}
        >
            <ContainerHeader className="insights-header" header={t("insight:housing-header")} showInfo={data != null} onEngagement={trackUsage}>
                <p>{t("insight:housing-information-first")}</p>
                <p>{t("insight:housing-information-second")}</p>
                <p>{t("insight:housing-information-third")}</p>
            </ContainerHeader>
            <div
                className={classNames("container-body insights-body", {
                    disabled: !data,
                })}
            >
                {data && (
                    <>
                        <h4>{t("insight:housing-subheader")}</h4>
                        <HousingChart period={10} result={data} infoTooltips={infoTooltips} onEngagement={trackUsage} />
                    </>
                )}
                {!data && (
                    <>
                        <i className="icon-placeholder icon-insight" />
                        <h4>{t("insight:incomplete-description")}</h4>
                    </>
                )}
            </div>
        </div>
    );
};

const Kalp = () => {
    const { t } = useTranslation();
    const { result, events } = useSelector((state: IRootState) => {
        return {
            result: state.result,
            events: state.events,
        };
    });
    const tracking = useTracking();
    const dispatch = useDispatch();

    const trackUsage = (label) => {
        const event = trackEngagement(label);

        if (!hasOccured(events, event)) {
            tracking.trackEvent(event);
            dispatch(setEvent({ event }));
        }
    };

    const data = result?.kalp;
    const tooltips = result?.kalpTooltips;
    const { singleAdultHouseholdExpense, twoAdultHouseholdExpense, childFullTimeExpense } = Config.get("standardValues");

    return (
        <div
            className={classNames("container insights-container kalp-insight", {
                disabled: !data,
            })}
        >
            <ContainerHeader className="insights-header" header={t("insight:kalp-header")} showInfo={data != null} onEngagement={trackUsage}>
                <p>{t("insight:kalp-information-first")}</p>
                <p>{t("insight:kalp-information-second")}</p>
                <p>
                    {t("insight:kalp-information-third", {
                        singleAdultHouseholdExpense: formatLocalAmount(singleAdultHouseholdExpense),
                        twoAdultHouseholdExpense: formatLocalAmount(twoAdultHouseholdExpense),
                        childFullTimeExpense: formatLocalAmount(childFullTimeExpense),
                    })}
                </p>
            </ContainerHeader>
            <div
                className={classNames("container-body insights-body", {
                    disabled: !data,
                })}
            >
                {data && (
                    <>
                        <h4>{t("insight:kalp-subheader")}</h4>
                        <KalpChart period={10} result={data} tooltips={tooltips} onEngagement={trackUsage} />
                        <DisclaimerComponent>{t("insight:kalp-savings-disclaimer")}</DisclaimerComponent>
                    </>
                )}
                {!data && (
                    <>
                        <i className="icon-placeholder icon-insight" />
                        <h4>{t("insight:incomplete-description")}</h4>
                    </>
                )}
            </div>
        </div>
    );
};

const Savings = () => {
    const { t } = useTranslation();
    const { result, events } = useSelector((state: IRootState) => {
        return {
            result: state.result,
            events: state.events,
        };
    });
    const tracking = useTracking();
    const dispatch = useDispatch();

    const trackUsage = (label) => {
        const event = trackEngagement(label);

        if (!hasOccured(events, event)) {
            tracking.trackEvent(event);
            dispatch(setEvent({ event }));
        }
    };

    const data: ISavingsResult | undefined = result?.savings;

    const renderResult = resultIsAvailable(data);
    const renderNoResult = resultIsEmpty(data);
    const renderMissingData = !data;
    const investmentInterest = formatLocalAmount(getInvestmentInterest() * 100, 0, 2);
    const savingsInterest = formatLocalAmount(getSavingsInterest() * 100, 0, 2);

    const tooltips = result?.savingsTooltips;

    return (
        <div
            className={classNames("container insights-container savings-insight", {
                disabled: !data,
            })}
        >
            <ContainerHeader className="insights-header" header={t("insight:savings-header")} showInfo={data != null} onEngagement={trackUsage}>
                <p>
                    {t("insight:savings-information-first", {
                        investmentInterest,
                    })}
                </p>
                <p>{t("insight:savings-information-second")}</p>
                <p>
                    {t("insight:savings-information-third", {
                        savingsInterest,
                    })}
                </p>
            </ContainerHeader>
            <div
                className={classNames("container-body insights-body", {
                    disabled: !data,
                })}
            >
                {renderResult && (
                    <>
                        <h4>{t("insight:savings-subheader")}</h4>
                        <SavingsChart period={10} result={data} tooltips={tooltips} onEngagement={trackUsage} />
                        <DisclaimerComponent>
                            {t("insight:savings-disclaimer", {
                                investmentInterest,
                                savingsInterest,
                            })}
                        </DisclaimerComponent>
                    </>
                )}
                {renderNoResult && <h4>{t("insight:no-savings")}</h4>}
                {renderMissingData && (
                    <>
                        <i className="icon-placeholder icon-insight" />
                        <h4>{t("insight:incomplete-description")}</h4>
                    </>
                )}
            </div>
        </div>
    );
};

const Loans = () => {
    const { t } = useTranslation();
    const tracking = useTracking();
    const dispatch = useDispatch();

    const { result, events } = useSelector((state: IRootState) => {
        return {
            result: state.result,
            events: state.events,
        };
    });

    const trackUsage = (label) => {
        const event = trackEngagement(label);

        if (!hasOccured(events, event)) {
            tracking.trackEvent(event);
            dispatch(setEvent({ event }));
        }
    };

    const data: ILoansResult | undefined = result?.loans;

    const renderResult = resultIsAvailable(data);
    const renderNoResult = resultIsEmpty(data);
    const renderMissingData = !data;

    return (
        <div
            className={classNames("container insights-container loans-insight", {
                disabled: !data,
            })}
        >
            <ContainerHeader className="insights-header" header={t("insight:loan-header")} showInfo={data != null} onEngagement={trackUsage}>
                <p>{t("insight:loan-information")}</p>
            </ContainerHeader>
            <div
                className={classNames("container-body insights-body", {
                    disabled: !data,
                })}
            >
                {renderResult && (
                    <>
                        <h4>{t("insight:loan-subheader")}</h4>
                        <LoansChart period={10} result={data} onEngagement={trackUsage} />
                    </>
                )}
                {renderNoResult && <h4>{t("insight:no-loans")}</h4>}
                {renderMissingData && (
                    <>
                        <i className="icon-placeholder icon-insight" />
                        <h4>{t("insight:incomplete-description")}</h4>
                    </>
                )}
            </div>
        </div>
    );
};

const Insight = () => {
    const { selectedSection } = useSelector((state: IRootState) => {
        return {
            selectedSection: state.selectedSection,
            result: state.result,
        };
    });

    switch (selectedSection) {
        case Sections.Housing:
            return (
                <AnimationSwitch>
                    <CSSAnimation key={selectedSection} classNames="animate-fade" timeout={300} in={true}>
                        <Housing />
                    </CSSAnimation>
                </AnimationSwitch>
            );
        case Sections.Kalp:
            return (
                <AnimationSwitch>
                    <CSSAnimation key={selectedSection} classNames="animate-fade" timeout={300} in={true}>
                        <Kalp />
                    </CSSAnimation>
                </AnimationSwitch>
            );
        case Sections.Loans:
            return (
                <AnimationSwitch>
                    <CSSAnimation key={selectedSection} classNames="animate-fade" timeout={300} in={true}>
                        <Loans />
                    </CSSAnimation>
                </AnimationSwitch>
            );
        case Sections.Savings:
            return (
                <AnimationSwitch>
                    <CSSAnimation key={selectedSection} classNames="animate-fade" timeout={300} in={true}>
                        <Savings />
                    </CSSAnimation>
                </AnimationSwitch>
            );
        default:
            return null;
    }
};

export default Insight;
