import classNames from "classnames";
import * as React from "react";
import { AccessibleButton } from "../accessibility/accessible-components";

export interface RadioOption {
    label: string;
    value: unknown;
}

export interface RadioProps {
    options: Array<RadioOption>;
    value: unknown;
    small?: boolean;
    label?: string;
    onChange: (value: unknown) => void;
}

export default class RadioComponent extends React.Component<RadioProps> {
    onClicked(option: RadioOption) {
        if (this.props.onChange) this.props.onChange(option.value);
    }

    renderOption(option: RadioOption) {
        return (
            <AccessibleButton
                className={classNames("radio", {
                    active: option.value === this.props.value,
                })}
                key={option.label}
                onClick={this.onClicked.bind(this, option)}
                role="switch"
                checked={option.value === this.props.value}
            >
                {option.label}
            </AccessibleButton>
        );
    }

    render() {
        const containerClass = this.props.small ? "radio-options radio-small" : "radio-options";
        return (
            <React.Fragment>
                {this.props.label ? <label>{this.props.label}</label> : null}
                <div className={containerClass}>{this.props.options.map((option) => this.renderOption(option))}</div>
            </React.Fragment>
        );
    }
}
