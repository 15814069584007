import * as React from "react";
import IndataStepComponent from "../../indata-step";
import { useSelector, useDispatch } from "react-redux";
import { setHousingEstimatedValue, setHousingMortgages, type IRootState } from "../../../reducers/root-reducer";

import NumberComponent from "../../form-controls/number-component";
import NumberStepComponent from "../../form-controls/number-step-component";
import { useTranslation } from "react-i18next";
import { ESTIMATED_HOUSING_VALUE, HOUSING_PRICE_STEP, HOUSING_MAX_PRICE, HOUSING_MIN_VALUE } from "../../../defaults";

export default function HousingExistingValueStepComponent(props) {
    const { estimatedValue, mortgages } = useSelector((state: IRootState) => {
        return {
            estimatedValue: state.userEditingData?.household?.estimatedValue || ESTIMATED_HOUSING_VALUE,
            mortgages: state.userEditingData?.household?.mortgages?.length || 0,
        };
    });

    const dispatch = useDispatch();
    const { t } = useTranslation();

    function onChangeEstimatedValue(value: number) {
        props.onValidate(value > 0);
        dispatch(setHousingEstimatedValue(value));
    }

    function onChangeMortgages(value: number) {
        props.onValidate(true);
        dispatch(setHousingMortgages(value));
    }

    React.useEffect(() => {
        props.onValidate(estimatedValue > 0);
    });

    return (
        <IndataStepComponent active={props.active}>
            <NumberComponent
                name="housing_estimatedvalue"
                unit={t("common:sek")}
                onChange={onChangeEstimatedValue}
                min={HOUSING_MIN_VALUE}
                step={HOUSING_PRICE_STEP}
                defaultMax={HOUSING_MAX_PRICE}
                label={t("housing:input-estimatedvalue")}
                value={estimatedValue}
            />
            <NumberStepComponent
                name="housing_loans"
                icon="house"
                onChange={onChangeMortgages}
                min={0}
                max={10}
                unit={t("common:pcs")}
                label={t("housing:input-loans")}
                subLabel={t("housing:input-houseloan")}
                value={mortgages}
            />
        </IndataStepComponent>
    );
}
