import * as React from "react";
import type { DataPoint, IResult } from "../models/result";
import type { IInfoTooltipProps } from "../components/chart/chart";
import { SectionBuilder } from "./section-builder";

// Should we move this into the result builder in the end to let it handle the merging.
// Could be done when we refactor calculations to all use resultbuilder.
export function mergeTooltips(tooltips: Array<IInfoTooltipProps>): Array<IInfoTooltipProps> {
    if (!tooltips) {
        return [];
    }

    const merged = tooltips.reduce((acc, current) => {
        acc[current.year] = acc[current.year] || [];
        acc[current.year].push(current);
        return acc;
    }, {});

    return Object.values<Array<IInfoTooltipProps>>(merged).map((t) => {
        if (t.length > 1) {
            // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
            const el = t.map((tt, index) => <li key={index}>{tt.text}</li>);
            return {
                year: t[0].year,
                text: <ul>{el}</ul>,
            };
        }
        return t[0];
    });
}

export class ResultBuilder {
    tooltips: Array<IInfoTooltipProps> = [];
    private numberOfYears: number;
    private sections: Array<SectionBuilder> = [];

    constructor(numberOfYears: number) {
        this.numberOfYears = numberOfYears;
    }

    addTooltip(year: number, text: string) {
        this.tooltips.push({ year, text });
    }

    createSection(name: string): SectionBuilder {
        const sectionBuilder = new SectionBuilder(name, this, this.numberOfYears);
        this.sections.push(sectionBuilder);
        return sectionBuilder;
    }

    getResult(summaryFunction?: (data: { [key: string]: DataPoint }) => Array<number>): { result: IResult; tooltips?: Array<IInfoTooltipProps> } {
        const data = {};
        for (const section of this.sections) {
            data[section.name] = section.getResult();
        }

        let summary: Array<number>;
        // Use a summary function if one is provided, otherwise automatically sum the results
        if (summaryFunction) {
            summary = summaryFunction(data);
        } else {
            summary = Array.from({ length: this.numberOfYears + 1 }, () => 0);
            for (const section of this.sections) {
                for (let year = 0; year <= this.numberOfYears; ++year) {
                    summary[year] += section.result[year];
                }
            }
        }

        const result = {
            summary: {
                now: summary[0],
                future: summary,
            },
        };

        for (const section of this.sections) {
            result[section.name] = section.getResult();
        }

        return { result, tooltips: this.tooltips };
    }
}
