import { tipsModule } from "../../utility/module-store";
import type { IRootState } from "../../reducers/root-reducer";
import { MortgageCalculationType } from "../../models/mortgage";
import { isKalpPositiveWithExtraDownpaymentAsIncome, LowKalpTipsComponent } from "./low-kalp-component";
import { isBankKalpNegativeOrZero, isBankKalpPositivForLTV } from "../../functions/kalp";

function isTipsActive(state: IRootState) {
    return (
        isBankKalpNegativeOrZero(state) && isBankKalpPositivForLTV(state, state.minLTVForTips) && isKalpPositiveWithExtraDownpaymentAsIncome(state)
    );
}

export default tipsModule(
    "low-kalp-new-loan",
    (state: IRootState) => !!state.userData && state.userData.household.calculationType === MortgageCalculationType.new && isTipsActive(state),
)(LowKalpTipsComponent);
