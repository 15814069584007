import type UserData from "../models/user-data";
import { accumulate } from "./calculations";
import type { DataPoint, IResult } from "../models/result";
import Config from "../config";
import { isRenovationScenarioActive, renovationMonthlyCost } from "../components/scenarios/renovation";
import { ResultBuilder } from "./result-builder";
import type { IInfoTooltipProps } from "components/chart/chart";
import i18next from "i18next";
import { sumDatapoints } from "../utility/datapoints";
import { DEFAULT_INVESTMENT_INTEREST_RATE, DEFAULT_SAVINGS_INTEREST_RATE, MONTHS_IN_YEAR } from "../defaults";

function compoundAccount(initialAmount: number, saving: number, interest: number, years?: number): Array<number> {
    const updatedYears = years || 1;

    const result = [initialAmount];
    return result.concat(
        accumulate(initialAmount, updatedYears, (accumulated) => {
            return Math.round(accumulated * (1 + interest) + saving * (12 + 5.5 * interest));
        }),
    );
}

function buildDataPoint(projection: Array<number>): DataPoint {
    const [now] = projection;

    return {
        now,
        future: projection,
    };
}

export default function forecastSavings(userData: UserData, years: number): { result: IResult; tooltips?: Array<IInfoTooltipProps> } {
    if (!userData.savings) {
        return undefined;
    }

    const account = userData.savings?.accountAmount;
    const savings = userData.savings?.accountMonthlySavings;
    const investment = userData.savings?.investmentsAmount;
    const monthlyInvestment = userData.savings?.investmentsMonthlySavings;

    const resultBuilder = new ResultBuilder(years);

    const investmentInterest = Number.isFinite(userData.scenarioDefinedInvestmentInterest)
        ? userData.scenarioDefinedInvestmentInterest
        : getInvestmentInterest();

    if (investment > 0 || monthlyInvestment > 0) {
        const projectedInvestments = buildDataPoint(compoundAccount(investment, monthlyInvestment, investmentInterest, years));
        resultBuilder.createSection("investments").fromData(projectedInvestments);
    }
    if (account > 0 || savings > 0) {
        const projectedAccount = buildDataPoint(compoundAccount(account, savings, getSavingsInterest(), years));
        resultBuilder.createSection("bankAccounts").fromData(projectedAccount);
    }
    if (isRenovationScenarioActive(userData.scenarioDefinedRenovationCost, userData.scenarioDefinedRenovationYear)) {
        const renovationIndex = userData.scenarioDefinedRenovationYear - new Date().getFullYear();
        const monthlySaving = renovationMonthlyCost(userData.scenarioDefinedRenovationCost, userData.scenarioDefinedRenovationYear);
        const projectedRenovation = buildDataPoint(
            compoundAccount(0, monthlySaving, 0, years).map((value, index) => {
                return index > renovationIndex ? 0 : value;
            }),
        );
        const target = userData.scenarioDefinedRenovationTarget;
        const targetDefined = typeof target === "string" && target.length > 0 ? `: ${target}` : "";
        const sectionName = i18next.t("scenario:renovation-section-name") + targetDefined;
        const section = resultBuilder.createSection(sectionName);
        section.fromData(projectedRenovation);

        if (renovationIndex <= 10) {
            resultBuilder.addTooltip(renovationIndex, sectionName);
        }
    }

    return resultBuilder.getResult();
}

export const getSavingsInterest = () => {
    const config: number = Config.get("savingsInterestRate");
    return typeof config === "number" ? config : DEFAULT_SAVINGS_INTEREST_RATE;
};

export const getInvestmentInterest = () => {
    const config: number = Config.get("investmentInterestRate");
    return typeof config === "number" ? config : DEFAULT_INVESTMENT_INTEREST_RATE;
};

export function forecastSavingsExpenses(userData: UserData, years: number): DataPoint {
    const accountMonthlySavings = userData.savings?.accountMonthlySavings;
    const accountSavingExpenses = accountMonthlySavings ? forecastExpenses(accountMonthlySavings, years + 1) : forecastExpenses(0, years + 1);

    const investmentsMonthlySavings = userData.savings?.investmentsMonthlySavings;
    const investmentSavingExpenses = investmentsMonthlySavings
        ? forecastExpenses(investmentsMonthlySavings, years + 1)
        : forecastExpenses(0, years + 1);

    const yearsToRenovation = userData.scenarioDefinedRenovationYear - new Date().getFullYear();
    const renovationMonthlySavings = Math.ceil(userData.scenarioDefinedRenovationCost / (yearsToRenovation * MONTHS_IN_YEAR));
    const renovationSavingExpenses =
        renovationMonthlySavings && yearsToRenovation
            ? forecastExpenses(renovationMonthlySavings, years + 1, yearsToRenovation)
            : forecastExpenses(0, years + 1);

    return sumDatapoints([accountSavingExpenses, investmentSavingExpenses, renovationSavingExpenses]);
}

function forecastExpenses(monthlyExpense: number, years: number, lastYearToSave?: number) {
    const expenses = {
        now: monthlyExpense,
        future: Array(years).fill(monthlyExpense),
    };
    if (lastYearToSave) {
        expenses.future.fill(0, lastYearToSave);
    }

    return expenses;
}
