import * as React from "react";
import * as d3 from "d3";
import { type IGraphProps, Graph } from "./chart";
import type { LinearPoint } from "../../models/linear";

export interface ILineProps extends IGraphProps {
    period?: number;
}

export class Line extends Graph<ILineProps> {
    render() {
        const { className, xScale, yScale, points } = this.props;

        const graphProps = {
            className: "summary-line",
        };

        if (className) graphProps.className = `${graphProps.className} ${className}`;

        const line = d3
            .line<LinearPoint>()
            .x((d) => {
                return xScale(d.x);
            })
            .y((d) => {
                return yScale(d.y);
            });

        return <path {...graphProps} d={line(points)} />;
    }
}
