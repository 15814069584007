const data = {};

const ConfigStore = {
    set: (name, value) => {
        data[name] = value;
    },
    get: (id) => data[id],
};

Object.freeze(ConfigStore);
export default ConfigStore;
