import * as React from "react";
import InputWizardComponent from "../input-wizard";
import HousingCalculationTypeStepComponent from "../steps/housing/housing-calculation-type-step";
import HousingValueStepComponent from "../steps/housing/housing-value-step";
import { type IRootState, setHousingType, setMortgageCalculationType } from "../../reducers/root-reducer";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { HousingType } from "../../models/user-data";
import HousingOccupantsStepComponent from "../steps/housing/housing-occupants-step";
import HousingAdultStepComponent from "../steps/housing/housing-adult-step";
import HousingMaintenanceStepComponent from "../steps/housing/housing-maintenance-step";
import HousingExistingValueStepComponent from "../steps/housing/housing-existing-value-step";
import HousingMortgageStepComponent from "../steps/housing/housing-mortgage-step";
import HousingMorgageAmortizationStepComponent from "../steps/housing/housing-mortgage-amortization-step";
import { useTracking } from "react-tracking";
import { trackUserInput, UserInputActions, StepNames } from "../../utility/analytics";
import { saveHousing, ViewMode, Sections, setNavigation } from "../../reducers/root-reducer";
import { getMortgageCalculationTypes } from "../../selectors/mortgage";
import HousingTypeStepComponent from "../steps/housing/housing-type-step";
import HousingFeeStepComponent from "../steps/housing/housing-fee-step";
import HousingPropertyTaxStep from "../steps/housing/housing-property-tax";
import { MortgageCalculationType } from "../../models/mortgage";
import { getHousingTypes } from "../../functions/housing-type";

export default function HousingWizard() {
    const dispatch = useDispatch();
    const tracking = useTracking();

    const { adults, calculationType, calculationTypes, housingType, loans, amortizationRequirementsAsDefault } = useSelector((state: IRootState) => {
        return {
            adults: state.userEditingData?.household?.adults || [],
            calculationType: state.userEditingData?.household?.calculationType,
            calculationTypes: getMortgageCalculationTypes(state.mortgage),
            housingType: state.userEditingData?.household?.housingType,
            loans: state.userEditingData?.household?.mortgages || [],
            amortizationRequirementsAsDefault: state.mortgage?.amortizationRequirementsAsDefault,
        };
    });

    function goToResult() {
        dispatch(saveHousing());
        tracking.trackEvent(trackUserInput(UserInputActions.STEP_COMPLETED, StepNames.HOUSING));
        dispatch(
            setNavigation({
                viewMode: ViewMode.Result,
                section: Sections.Housing,
            }),
        );
    }

    function emitStepStarted() {
        tracking.trackEvent(trackUserInput(UserInputActions.STEP_STARTED, StepNames.HOUSING));
    }

    const skipCalculationTypeStep = calculationTypes.length === 1;
    if (!calculationType && skipCalculationTypeStep) {
        dispatch(
            setMortgageCalculationType({
                type: calculationTypes[0],
                useAmortizationRequirement: amortizationRequirementsAsDefault,
            }),
        );
    }

    const children = [];
    if (!skipCalculationTypeStep) {
        children.push(<HousingCalculationTypeStepComponent key="calculationtype" />);
    }

    const housingTypes = getHousingTypes();
    const skipHousingTypeStep = housingTypes.length === 1;
    if (!housingType && skipHousingTypeStep) {
        dispatch(setHousingType(housingTypes[0]));
    }

    if (!skipHousingTypeStep) {
        children.push(<HousingTypeStepComponent key="type" />);
    }

    if (calculationType === MortgageCalculationType.new) {
        children.push(<HousingValueStepComponent key="value" />, <HousingOccupantsStepComponent key="occupants" />);
        for (let key = 0; key < adults.length; ++key) {
            children.push(<HousingAdultStepComponent key={`adult${key}`} index={key} />);
        }
        if (housingType === HousingType.condominium) {
            children.push(<HousingFeeStepComponent key="fee" />, <HousingMaintenanceStepComponent key="maintenance" />);
        }
        if (housingType === HousingType.house || housingType === HousingType.cottage) {
            children.push(<HousingMaintenanceStepComponent key="maintenance" />, <HousingPropertyTaxStep key="propertytax" />);
        }
    } else if (calculationType === MortgageCalculationType.move) {
        children.push(<HousingExistingValueStepComponent key="existingvalue" />);
        for (let key = 0; key < loans.length; ++key) {
            children.push(<HousingMortgageStepComponent key={`mortgage${key}`} index={key} />);
            if (!amortizationRequirementsAsDefault) {
                children.push(<HousingMorgageAmortizationStepComponent key={`mortgage${key}_amortization`} index={key} />);
            }
        }
        children.push(<HousingOccupantsStepComponent key="occupants" />);
        for (let key = 0; key < adults.length; ++key) {
            children.push(<HousingAdultStepComponent key={`adult${key}`} index={key} />);
        }
        if (housingType === HousingType.condominium) {
            children.push(<HousingFeeStepComponent key="fee" />, <HousingMaintenanceStepComponent key="maintenance" />);
        }
        if (housingType === HousingType.house || housingType === HousingType.cottage) {
            children.push(<HousingMaintenanceStepComponent key="maintenance" />, <HousingPropertyTaxStep key="propertytax" />);
        }
    }

    return (
        <InputWizardComponent onFinalStep={goToResult} onFirstStep={emitStepStarted}>
            {children}
        </InputWizardComponent>
    );
}
