import * as React from "react";
import IndataStepComponent from "../../indata-step";
import { useSelector, useDispatch } from "react-redux";
import { setKalpChildren, type IRootState } from "../../../reducers/root-reducer";
import NumberStepComponent from "../../form-controls/number-step-component";
import { useTranslation } from "react-i18next";

export default function KalpChildrenStepComponent(props) {
    const { children } = useSelector((state: IRootState) => {
        return {
            children: state.userEditingData?.kalp?.children?.length || 0,
        };
    });

    const dispatch = useDispatch();
    const { t } = useTranslation();

    function onChangeChildren(value: number) {
        props.onValidate(value > 0);
        dispatch(setKalpChildren(value));
    }

    React.useEffect(() => {
        props.onValidate(true);
    });

    return (
        <IndataStepComponent active={props.active}>
            <NumberStepComponent
                name="kalp_children"
                icon="child"
                onChange={onChangeChildren}
                min={0}
                max={10}
                unit={t("common:pcs")}
                label={t("kalp:input-household-children")}
                subLabel={t("kalp:input-children")}
                value={children}
            />
        </IndataStepComponent>
    );
}
