import * as React from "react";
import IndataStepComponent from "../../indata-step";
import { useSelector, useDispatch } from "react-redux";
import { type IRootState, setCarLoanInterest } from "../../../reducers/root-reducer";
import NumberComponent from "../../form-controls/number-component";
import { useTranslation } from "react-i18next";
import OverlineComponent from "../../typography/overline-component";

export default function CarLoanSecondStepComponent(props) {
    const { interest } = useSelector((state: IRootState) => {
        return {
            interest: state.userEditingData.loans.carLoans[props.index].interest,
        };
    });

    const dispatch = useDispatch();
    const { t } = useTranslation();

    function onChangeFirst(value: number) {
        props.onValidate(true);
        const interest = value / 100;
        dispatch(setCarLoanInterest({ index: props.index, value: interest }));
    }

    React.useEffect(() => {
        props.onValidate(true);
    });

    return (
        <IndataStepComponent active={props.active}>
            <OverlineComponent>{t("loan:carloan-enumeration", { index: props.index + 1 })}</OverlineComponent>
            <NumberComponent
                name={`loan_carloan_${props.index}_interest`}
                unit="%"
                onChange={onChangeFirst}
                min={0}
                step={0.05}
                defaultMax={15}
                label={t("loan:input-carloan-interest")}
                value={interest * 100}
            />
        </IndataStepComponent>
    );
}
