export function getOrientation(): string {
    if (window.screen?.orientation) {
        if (window.screen.orientation.type.match(/portrait/)) {
            return "portrait";
        }
        return "landscape";
    }
    if (window.orientation !== undefined) {
        let orientation = 0;
        if (typeof window.orientation === "string") {
            Number.parseInt(window.orientation);
        } else {
            orientation = window.orientation;
        }

        if (Math.abs(orientation) === 90) {
            return "landscape";
        }
        return "portrait";
    }
}

export function determineLayout(element: HTMLElement): string {
    const width = element.clientWidth;

    if (width <= 720) {
        return "small";
    }
    if (width <= 1280) {
        return "medium";
    }

    return "large";
}
