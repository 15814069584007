import * as React from "react";

import ResultsComponent from "./results-component";
import { determineLayout } from "../utility/screen";
import Insight from "./insight";
import OffersComponent from "./offers";
import Scenarios from "./scenarios";

import { trackWidgetEvent, WidgetActions, trackActivity } from "../utility/analytics";
import track, { type TrackingHook } from "react-tracking";
import initTranslation, { type ITranslation } from "../utility/internationalization";

import { FocusVisibleManager } from "use-focus-visible";
import ActivityWatcher from "../utility/activity-watcher";
import ConfigStore from "../config";
import TipsComponent, { type ITipsConfiguration } from "./tips-component";

interface IPrognosisProps {
    tracking: TrackingHook;
    language?: string;
    translation?: ITranslation;
    debug?: boolean;
    tipsModules?: Array<string | ITipsConfiguration>;
    scenarios?: Array<string>;
}

interface IPrognosisState {
    layoutClass: string;
}

@track(
    {},
    {
        dispatchOnMount: () => trackWidgetEvent(WidgetActions.LOADED),
    },
)
class PrognosisComponent extends React.Component<IPrognosisProps, IPrognosisState> {
    mainContainerRef: React.RefObject<HTMLDivElement> = React.createRef();
    activityWatcher: ActivityWatcher = new ActivityWatcher(
        (activeTime: number) => {
            this.props.tracking.trackEvent(trackActivity(activeTime));
        },
        ConfigStore.get("userActivityInterval"),
        ConfigStore.get("userActivityIdleTime"),
    );

    constructor(props) {
        super(props);

        this.state = {
            layoutClass: "layout-medium",
        };
        this.applyLayout = this.applyLayout.bind(this);
        initTranslation(props.language, props.translation, props.debug);
    }

    applyLayout() {
        this.setState({
            layoutClass: `layout-${determineLayout(this.mainContainerRef.current)}`,
        });
    }

    componentDidMount() {
        window.addEventListener("resize", this.applyLayout);
        this.applyLayout();

        this.activityWatcher.watch(this.mainContainerRef.current);
    }

    getLayoutClass() {
        if (this.state.layoutClass) {
            return `main-container ${this.state.layoutClass}`;
        }
        return "main-container";
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.applyLayout);
        this.activityWatcher.unwatch(this.mainContainerRef.current);
    }

    render() {
        return (
            <FocusVisibleManager>
                <div className={this.getLayoutClass()} ref={this.mainContainerRef}>
                    <div className="inner-container">
                        <ResultsComponent />
                        <TipsComponent availableModules={this.props.tipsModules || []} />
                        <OffersComponent />
                    </div>
                    <div className="inner-container">
                        <Insight />
                        <Scenarios activeScenarios={this.props.scenarios} />
                    </div>
                </div>
            </FocusVisibleManager>
        );
    }
}

export default PrognosisComponent;
