import * as React from "react";
import IndataStepComponent from "../../indata-step";
import { useSelector, useDispatch } from "react-redux";
import { type IRootState, setStudentLoans, setPrivateLoans } from "../../../reducers/root-reducer";
import NumberStepComponent from "../../form-controls/number-step-component";
import { useTranslation } from "react-i18next";

export default function LoanFirstStepComponent(props) {
    const { studentLoans, privateLoans } = useSelector((state: IRootState) => {
        return {
            studentLoans: state.userEditingData?.loans?.studentLoans?.length || 0,
            privateLoans: state.userEditingData?.loans?.privateLoans?.length || 0,
        };
    });

    const dispatch = useDispatch();
    const { t } = useTranslation();

    function onChangeStudentLoans(value: number) {
        props.onValidate(true);
        dispatch(setStudentLoans(value));
    }

    function onChangePrivateLoans(value: number) {
        props.onValidate(true);
        dispatch(setPrivateLoans(value));
    }

    React.useEffect(() => {
        props.onValidate(true);
    });

    return (
        <IndataStepComponent active={props.active}>
            <NumberStepComponent
                name="studentloans"
                icon="student"
                onChange={onChangeStudentLoans}
                min={0}
                max={10}
                unit={t("common:pcs")}
                subLabel={t("loan:input-studentloans")}
                value={studentLoans}
            />
            <NumberStepComponent
                name="privateloans"
                icon="private"
                onChange={onChangePrivateLoans}
                min={0}
                max={10}
                unit={t("common:pcs")}
                subLabel={t("loan:input-privateloans")}
                value={privateLoans}
            />
        </IndataStepComponent>
    );
}
