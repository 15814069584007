import * as React from "react";
import IndataStepComponent from "../../indata-step";
import { useSelector, useDispatch } from "react-redux";
import { type IRootState, setAdditionalHousingMortgageAmortization, setAdditionalHousingMortgageAmount } from "../../../reducers/root-reducer";
import NumberComponent from "../../form-controls/number-component";
import { useTranslation } from "react-i18next";
import DisclaimerComponent from "../../typography/disclaimer-component";
import OverlineComponent from "../../typography/overline-component";
import { DEFAULT_ADDITIONAL_HOUSING_MORTGAGE_MAX } from "../../../defaults";
import { convertToPercent } from "../../../functions/calculations";

export default function KalpAdditionalHousingFeeStep(props) {
    const { amount, amortization, interest } = useSelector((state: IRootState) => {
        return {
            amount: state.userEditingData.kalp.additionalHousing.data.mortgage.amount,
            amortization: state.userEditingData.kalp.additionalHousing.data.mortgage.amortization,
            interest: state.userEditingData.kalp.additionalHousing.data.mortgage.interest,
        };
    });

    const dispatch = useDispatch();
    const { t } = useTranslation();

    function onChangeAmount(value: number) {
        props.onValidate(value >= 0);
        dispatch(setAdditionalHousingMortgageAmount(value));
    }

    function onChangeAmortization(value: number) {
        props.onValidate(true);
        dispatch(setAdditionalHousingMortgageAmortization(value));
    }

    React.useEffect(() => {
        props.onValidate(true);
    });

    return (
        <IndataStepComponent active={props.active}>
            <OverlineComponent>{t("kalp:additional-housing")}</OverlineComponent>
            <DisclaimerComponent>{t("kalp:part-of-additional-housing")}</DisclaimerComponent>
            <NumberComponent
                name={"additional_housing_mortgage_amount"}
                unit={t("common:sek")}
                onChange={onChangeAmount}
                min={0}
                step={50000}
                defaultMax={DEFAULT_ADDITIONAL_HOUSING_MORTGAGE_MAX}
                label={t("kalp:input-mortgage-amount")}
                value={amount}
            />
            <NumberComponent
                name={"additional_housing_mortgage_amortization"}
                unit={t("common:sek")}
                onChange={onChangeAmortization}
                min={0}
                step={100}
                defaultMax={20000}
                label={t("kalp:input-amortization")}
                value={amortization}
            />
            <DisclaimerComponent>{t("kalp:input-interest-rate-disclaimer", { interest: convertToPercent(interest) })}</DisclaimerComponent>
        </IndataStepComponent>
    );
}
