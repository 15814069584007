import * as React from "react";
import * as ReactDOM from "react-dom";
import track from "react-tracking";
import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";

import { getConfiguration } from "@ClientConfig";
import Config from "./config";
import type UserData from "models/user-data";
import PrognosisComponent from "./components/prognosis-component";
import { rootReducer, type IRootState, ViewMode, Sections, setUserData, setselectedViewMode } from "./reducers/root-reducer";
import EffectiveInterestCalculator from "./functions/effective-interest";
import { type IPrognosisParametersOverrides, overrideOffersConfig } from "./models/config";
import { DEFAULT_STANDARD_VALUES } from "./defaults";
import packageJson from "../package.json";

function requireAll(r) {
    r.keys().forEach(r);
}
requireAll(require.context("./modules/", true, /\.tsx?$/));

function composeEnhancers() {
    if (process.env.NODE_ENV === "production") {
        return compose;
    }
    return (
        // biome-ignore lint/complexity/useLiteralKeys: <explanation>
        typeof window === "object" && (window["__REDUX_DEVTOOLS_EXTENSION_COMPOSE__"] ? window["__REDUX_DEVTOOLS_EXTENSION_COMPOSE__"]({}) : compose)
    );
}

export default class PrognosisWidget {
    static el;
    static store;
    static resizeListener;
    static version = packageJson.version;

    static mount(parentElement: string | HTMLElement, configurationOverrides?: IPrognosisParametersOverrides) {
        const parameters = overrideOffersConfig(getConfiguration(configurationOverrides), configurationOverrides?.offers);

        const onEvent =
            parameters.onEvent ||
            (() => {
                return;
            });

        Config.set("onRating", parameters.onRating);
        Config.set("mortgageInterestRateOffer", parameters.mortgageInterestRateOffer);
        Config.set("interestRateListOffer", parameters.interestRateList);
        Config.set("investmentInterestRate", parameters.investmentInterestRate);
        Config.set("savingsInterestRate", parameters.savingsInterestRate);
        Config.set("privateLoanInterestRate", parameters.privateLoanInterestRate);
        Config.set("carLoanInterestRate", parameters.carLoanInterestRate);
        Config.set("additionalHousingInterestRate", parameters.additionalHousingInterestRate);
        if (parameters.defaultValues) {
            if (parameters.defaultValues.household) {
                Config.set("housingDefaults", parameters.defaultValues.household);
            }
            if (parameters.defaultValues.kalp) {
                Config.set("kalpDefaults", parameters.defaultValues.kalp);
            }
            if (parameters.defaultValues.loans) {
                Config.set("loansDefaults", parameters.defaultValues.loans);
            }
            if (parameters.defaultValues.savings) {
                Config.set("savingsDefaults", parameters.defaultValues.savings);
            }
        }
        Config.set("housingTypes", parameters.housingTypes);
        Config.set("userActivityInterval", parameters?.tracking?.userActivityReportInterval || 0);
        Config.set("userActivityIdleTime", parameters?.tracking?.userActivityIdleTimeout || 0);
        Config.set("hideEffectiveInterest", parameters.hideEffectiveInterest ?? false);
        Config.set("standardValues", parameters.standardValues || DEFAULT_STANDARD_VALUES);

        const initialState: IRootState = {
            forecastPeriod: 10,
            selectedViewMode: ViewMode.Input,
            selectedSection: Sections.Housing,
            sectionCollapsed: false,
            minDownpaymentRate: parameters.minDownpaymentRate || 0.15,
            calculationInterestRate: parameters.calculationInterestRate || 0.07,
            offers: parameters.offers,
            userEditingData: undefined,
            userData: undefined,
            mortgage: parameters.mortgage,
            minLTVForTips: parameters.minLTVForTips || 0.25,
        };

        const store = createStore(rootReducer, initialState, composeEnhancers()(applyMiddleware(thunk)));

        if (parameters?.initialState) {
            store.dispatch(setUserData(parameters.initialState));
            store.dispatch(setselectedViewMode(ViewMode.Result));
        }

        const PrognosisComponentWithTracking = track(
            {},
            {
                dispatch: (data) => onEvent(data),
            },
        )(PrognosisComponent);

        const element = React.createElement(PrognosisComponentWithTracking, {
            language: parameters.language,
            translation: parameters.translation,
            debug: parameters.debug,
            tipsModules: parameters.tipsModules,
            scenarios: parameters.scenarios,
        });
        const component = React.createElement(Provider, { store }, element);

        function doRender() {
            if (PrognosisWidget.el) {
                throw new Error("PrognosisWidget is already mounted, unmount first");
            }
            const el = document.createElement("div");
            el.setAttribute("class", "cleanslate");

            if (!parentElement) {
                throw new Error("Invalid parent element for widget.");
            }

            if (typeof parentElement === "string") {
                document.querySelector(parentElement).appendChild(el);
            } else if (parentElement instanceof HTMLElement) {
                (parentElement as HTMLElement).appendChild(el);
            } else {
                throw new TypeError("Invalid parent element when initializing widget");
            }

            ReactDOM.render(component, el);
            PrognosisWidget.el = el;
            PrognosisWidget.store = store;
        }

        if (document.readyState === "complete") {
            doRender();
        } else {
            window.addEventListener("load", () => {
                doRender();
            });
        }
    }

    static getEffectiveInterest(nominalInterest: number) {
        return EffectiveInterestCalculator.withInterest(nominalInterest);
    }

    static getUserData(): UserData {
        const state = PrognosisWidget.store.getState();

        if (!state || !state.userData) {
            return null;
        }

        return state.userData;
    }

    static unmount() {
        if (!PrognosisWidget.el) {
            throw new Error("PrognosisWidget is not mounted, mount first");
        }

        ReactDOM.unmountComponentAtNode(PrognosisWidget.el);
        PrognosisWidget.el.parentNode.removeChild(PrognosisWidget.el);
        PrognosisWidget.el = null;
        PrognosisWidget.store = null;
    }
}
