import * as React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";

import Config from "../../config";
import type UserData from "../../models/user-data";
import type { Loan } from "../../models/user-data";
import { MortgageCalculationType } from "../../models/mortgage";
import { constructUserDataInterface } from "../../user-data-interface";
import { getInterestRate, getMortgage } from "../../functions/household";
import EffectiveInterestCalculator from "../../functions/effective-interest";
import NumberComponent from "../form-controls/number-component";
import { formatLocalAmount } from "../../utility/number-formatter";
import { ScenarioTemplate, IncompleteStateScenario, type IScenarioProps } from "../scenarios";
import DisclaimerComponent from "../typography/disclaimer-component";
import SelectComponent from "../form-controls/select-component";
import { setInterestRateScenario, resetInterestRateScenario, type IRootState } from "../../reducers/root-reducer";
import { applyScenariosToUserData } from "../../functions/calculations";
import { ScenarioEvent } from "../../utility/analytics";

const getInterestRateList = (userData: UserData) => {
    const offerFunc = Config.get("interestRateListOffer");
    const interestRateList = typeof offerFunc === "function" && offerFunc(constructUserDataInterface(userData));
    return interestRateList;
};

const getInterestRateOptions = createSelector([getInterestRateList], (interestRateList) => interestRateList);

const getInterestRateScenarioData = (state: IRootState) => {
    return state.scenarioData?.mortgageInterestRate;
};

const calculateDefaultInterestRate = (state: IRootState) => {
    const userData = state.userData;
    if (!userData) {
        return Number.NaN;
    }

    const data =
        userData?.household?.calculationType === MortgageCalculationType.new ? getInterestRate(userData) : avgInterestRateForExistingLoans(userData);

    return data;
};

function avgInterestRateForExistingLoans(userData) {
    const mortgages: Array<Loan> = userData.household.mortgages;

    const calc = mortgages.reduce(
        (acc, loan) => {
            return {
                totalAmount: acc.totalAmount + loan.amount,
                interestExpense: acc.interestExpense + loan.amount * loan.interest,
            };
        },
        { totalAmount: 0, interestExpense: 0 },
    );

    const avgInterest = calc.interestExpense / calc.totalAmount;
    return avgInterest;
}

export const InterestScenario = (props: IScenarioProps) => {
    const { onBack, onEngagement } = props;

    const { t } = useTranslation();
    const { userData, mortgageData } = useSelector((state: IRootState) => {
        return {
            userData: state.userData,
            mortgageData: state.scenarioData?.mortgageData,
        };
    });

    const currentMortgage = getMortgage(userData, mortgageData);
    const isDefaultInterestNaN = Number.isNaN(useSelector(calculateDefaultInterestRate));

    if (!userData) {
        return <IncompleteStateScenario name={t("scenario:interest-header")} onBack={onBack} category={t("dynamic:house")} />;
    }
    if (!(currentMortgage > 0) || isDefaultInterestNaN) {
        return (
            <IncompleteStateScenario
                description={t("scenario:scenario-interest-no-loans")}
                name={t("scenario:interest-header")}
                onBack={onBack}
                category={t("dynamic:house")}
            />
        );
    }
    return <InterestScenarioWithAvailableUserData onBack={onBack} onEngagement={onEngagement} />;
};

const InterestScenarioWithAvailableUserData = (props) => {
    const { onBack, onEngagement } = props;

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { userData, scenarioData, calculationInterestRate } = useSelector((state: IRootState) => {
        return {
            userData: state.userData,
            scenarioData: state.scenarioData,
            calculationInterestRate: state.calculationInterestRate,
        };
    });
    const interestRateScenarioData = useSelector(getInterestRateScenarioData);
    const selectedFixationPeriod =
        interestRateScenarioData && interestRateScenarioData?.fixationPeriod !== "none" ? interestRateScenarioData.fixationPeriod : "none";
    const defaultInterestRate = useSelector(calculateDefaultInterestRate);
    const selectedInterest =
        interestRateScenarioData && Number.isFinite(interestRateScenarioData.interest) ? interestRateScenarioData.interest : defaultInterestRate;
    const userDataAfterScenarioApplied = applyScenariosToUserData(userData, scenarioData);
    const interestList = getInterestRateOptions(userDataAfterScenarioApplied) || [];
    const resetScenario = () => dispatch(resetInterestRateScenario());

    const setSelected = (item) => {
        onEngagement(ScenarioEvent.INTEREST);
        if (item) {
            dispatch(
                setInterestRateScenario({
                    fixationPeriod: item.id,
                    interest: Number(item.value),
                }),
            );
        } else {
            resetScenario();
        }
    };
    const manualInterestRateChange = (value) => {
        onEngagement(ScenarioEvent.INTEREST);
        const interest = value / 100;
        dispatch(
            setInterestRateScenario({
                fixationPeriod: "none",
                interest,
            }),
        );
    };

    const scenarioActive = interestRateScenarioData && Number.isFinite(interestRateScenarioData.interest);
    const effectiveInterest = EffectiveInterestCalculator.withInterest(selectedInterest);

    const formattedEffectiveInterest = formatLocalAmount(Math.round(effectiveInterest * 10000) / 100, 2, 2);
    const formattedCalculationInterest = formatLocalAmount(calculationInterestRate * 100, 0, 2);

    const hasInterestList = interestList != null && interestList.length > 0;
    const hideEffectiveInterest = Config.get("hideEffectiveInterest");

    return (
        <ScenarioTemplate
            name={t("scenario:interest-header")}
            onBack={onBack}
            onReset={resetScenario}
            scenarioActivated={scenarioActive}
            category={t("dynamic:house")}
        >
            {hasInterestList && (
                <SelectComponent
                    id="interest-list"
                    onChange={setSelected}
                    items={interestList}
                    value={selectedFixationPeriod}
                    placeholder={t("scenario:interest-default-option-label")}
                    label={t("scenario:interest-select-label")}
                />
            )}
            <NumberComponent
                name="interest_rate_scenario"
                unit={"%"}
                onChange={manualInterestRateChange}
                min={0}
                step={0.1}
                defaultMax={7}
                label={!hasInterestList ? t("scenario:interest-select-label") : ""}
                value={selectedInterest * 100}
            />
            {!hideEffectiveInterest && <label>{`${t("scenario:interest-effective-rate")} ${formattedEffectiveInterest} %`}</label>}
            <DisclaimerComponent>{t("scenario:interest-disclaimer", { rate: formattedCalculationInterest })}</DisclaimerComponent>
        </ScenarioTemplate>
    );
};
