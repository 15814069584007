import React from "react";
import { CSSTransition, Transition, SwitchTransition } from "react-transition-group";

export function CSSAnimation(props) {
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    if ((window as any).Cypress) {
        if (props.in === false) {
            return null;
        }
        // biome-ignore lint/complexity/noUselessFragments: <explanation>
        return <React.Fragment>{props.children}</React.Fragment>;
    }

    return <CSSTransition {...props}>{props.children}</CSSTransition>;
}

export function AnimationTransition(props) {
    const { ...childProps } = props;

    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    if ((window as any).Cypress) {
        if (props.in === false) {
            return null;
        }
        if (typeof props.children === "function") {
            // biome-ignore lint/complexity/noUselessFragments: <explanation>
            return <React.Fragment>{props.children("exited", childProps)}</React.Fragment>;
        }
        // biome-ignore lint/complexity/noUselessFragments: <explanation>
        return <React.Fragment>{props.children}</React.Fragment>;
    }

    return <Transition {...props}>{props.children}</Transition>;
}

export function AnimationSwitch(props) {
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    if ((window as any).Cypress) {
        // biome-ignore lint/complexity/noUselessFragments: <explanation>
        return <React.Fragment>{props.children}</React.Fragment>;
    }

    return <SwitchTransition {...props}>{props.children}</SwitchTransition>;
}
