import * as React from "react";
import IndataStepComponent from "../../indata-step";
import { useSelector, useDispatch } from "react-redux";
import { type IRootState, setHousingPropertyTax } from "../../../reducers/root-reducer";
import NumberComponent from "../../form-controls/number-component";
import { useTranslation } from "react-i18next";
import { DEFAULT_PROPERTY_TAX, DEFAULT_PROPERTY_TAX_STEP, MAX_PROPERTY_TAX, MIN_PROPERTY_TAX } from "../../../defaults";
import DisclaimerComponent from "../../typography/disclaimer-component";
import { formatLocalAmount } from "../../../utility/number-formatter";

export default function HousingPropertyTaxStep(props) {
    const { propertyTax } = useSelector((state: IRootState) => {
        return {
            propertyTax:
                state.userEditingData?.household?.propertyTax !== undefined ? state.userEditingData?.household?.propertyTax : MAX_PROPERTY_TAX,
        };
    });

    const dispatch = useDispatch();
    const { t } = useTranslation();

    function onChangeSize(value: number) {
        props.onValidate(value >= 0);
        dispatch(setHousingPropertyTax(value));
    }

    React.useEffect(() => {
        props.onValidate(propertyTax >= MIN_PROPERTY_TAX);
    });

    return (
        <IndataStepComponent active={props.active}>
            <NumberComponent
                name="housing_property_tax"
                unit={t("common:sek")}
                onChange={onChangeSize}
                min={MIN_PROPERTY_TAX}
                step={DEFAULT_PROPERTY_TAX_STEP}
                defaultMax={MAX_PROPERTY_TAX}
                label={t("housing:input-property-tax")}
                value={propertyTax}
            />
            <DisclaimerComponent>
                {t("housing:input-property-tax-disclaimer", { propertyTax: formatLocalAmount(DEFAULT_PROPERTY_TAX) })}
            </DisclaimerComponent>
        </IndataStepComponent>
    );
}
