// biome-ignore lint/style/useImportType: <explanation>
import * as React from "react";

interface Option {
    id: string;
    label: string;
    value: string;
}

interface SelectProps {
    id: string;
    label: string;
    placeholder?: string;
    value: string;
    items: Array<Option>;
    dataCy?: string;
    onChange: (item: Option) => void;
}

export default function SelectComponent(props: SelectProps) {
    const { id, label, value, items, placeholder, onChange } = props;

    function emitChange(event: React.ChangeEvent<HTMLSelectElement>): void {
        if (!onChange) {
            return;
        }

        const value = event.target.value;
        if (value === null) {
            onChange(null);
        }

        const item = items.find((item) => item.id === value);
        onChange(item);
    }

    return (
        <div>
            <label>{label}</label>
            <div className="select-container">
                <i className="icon-arrow-down" />
                {/* TODO: The no-onchange recommendation is deprecated (will be */}
                {/* removed from the recommended rules upstream soon) */}
                <select id={id} value={value} onChange={emitChange}>
                    {placeholder && (
                        <option value="none" disabled>
                            {placeholder}
                        </option>
                    )}
                    {items.map((item) => {
                        return (
                            <option key={item.id} value={item.id}>
                                {item.label}
                            </option>
                        );
                    })}
                </select>
            </div>
        </div>
    );
}
