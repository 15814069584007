import classNames from "classnames";
import * as React from "react";

import { useTranslation } from "react-i18next";

export interface ILegendProps {
    labels: Array<string>;
    className: string;
}

export default function Legend(props: ILegendProps) {
    const { labels, className } = props;

    const { t, i18n } = useTranslation();

    return (
        <ul
            className={classNames("legend", {
                [`${className}`]: className !== null,
            })}
        >
            {labels.map((x, i) => (
                // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                <li key={i}>{i18n.exists(`dynamic:${x}`) ? t(`dynamic:${x}`) : x}</li>
            ))}
        </ul>
    );
}
