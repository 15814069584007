import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import React from "react";

import { ScenarioTemplate, IncompleteStateScenario, type IScenarioProps } from "../scenarios";
import { type IRootState, resetMaintenanceScenario, setMaintenanceScenario } from "../../reducers/root-reducer";
import NumberComponent from "../form-controls/number-component";
import { MAINTENANCE_STEP, MAX_MAINTENANCE, MIN_MAINTENANCE } from "../../defaults";
import DisclaimerComponent from "../typography/disclaimer-component";
import { ScenarioEvent } from "../../utility/analytics";

const maintenanceScenarioData = (state: IRootState) => {
    return state.scenarioData?.mortgageMaintenance;
};

const maintenanceUserData = (state: IRootState) => {
    return state.result?.housing?.maintenance?.now;
};

export const MaintenanceScenario = (props: IScenarioProps) => {
    const { onBack, onEngagement } = props;

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const scenarioDefined = useSelector(maintenanceScenarioData);
    const userDefined = useSelector(maintenanceUserData);
    const userData = useSelector((state: IRootState) => state.userData);

    if (!userData) {
        return <IncompleteStateScenario name={t("scenario:maintenance-header")} onBack={onBack} category={t("dynamic:house")} />;
    }

    const scenarioActivated = Number.isFinite(scenarioDefined);

    const selectedMaintenance = scenarioActivated ? scenarioDefined : userDefined;

    const resetScenario = () => dispatch(resetMaintenanceScenario());
    const setScenario = (value) => {
        onEngagement(ScenarioEvent.MAINTENANCE);
        dispatch(setMaintenanceScenario({ value: value }));
    };

    return (
        <ScenarioTemplate
            name={t("scenario:maintenance-header")}
            onBack={onBack}
            onReset={resetScenario}
            scenarioActivated={scenarioActivated}
            category={t("dynamic:house")}
        >
            <NumberComponent
                name="maintenance_scenario"
                unit={t("common:sek")}
                onChange={setScenario}
                min={MIN_MAINTENANCE}
                step={MAINTENANCE_STEP}
                defaultMax={MAX_MAINTENANCE}
                label={t("scenario:maintenance-label")}
                value={selectedMaintenance}
            />
            <DisclaimerComponent>{t("scenario:maintenance-disclaimer")}</DisclaimerComponent>
        </ScenarioTemplate>
    );
};
