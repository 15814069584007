import type { IStandardValues } from "models/config";
import { InputState } from "./models/input-state";
import { AmortizationType, HousingType } from "./models/user-data";

export const DEFAULT_ADDITIONAL_HOUSING_INTEREST_RATE = 0.02;

export const DEFAULT_PRIVATE_LOAN_INTEREST_RATE = 0.1;
export const DEFAULT_CAR_LOAN_INTEREST_RATE = 0.1;

export const ESTIMATED_HOUSING_VALUE = 3000000;

export const AMORTIZATION_STEP = 500;

export const ADULT_AGE_MAX_SLIDER = 100;
export const ADULT_AGE_MIN = 18;
export const ADULT_AGE_SLIDER_STEP = 1;

export const HOUSING_PRICE_STEP = 50000;

export const HOUSING_MAX_PRICE = 20000000;

export const DOWNPAYMENT_STEP = 50000;

export const HOUSING_MIN_VALUE = 100000;

export const DEFAULT_MORTGAGE_AMORTIZATION = 3333;

export const DEFAULT_MORTGAGE_INTEREST = 0;

export const DEFAULT_SAVINGS_INTEREST_RATE = 0;
export const DEFAULT_INVESTMENT_INTEREST_RATE = 0.07;

export const DEFAULT_ADULT_AGE = 35;
export const DEFAULT_ADULT_INCOME = 32000;
export const ADULT_INCOME_MAX_SLIDER = 100000;
export const ADULT_INCOME_MIN = 0;
export const ADULT_INCOME_STEP = 500;

export const DEFAULT_PROPERTY_TAX = 9525;
export const DEFAULT_PROPERTY_TAX_STEP = 100;
export const MAX_PROPERTY_TAX = 10000;
export const MIN_PROPERTY_TAX = 0;

export const MIN_MAINTENANCE = 0;
export const MAX_MAINTENANCE = 20000;
export const MAINTENANCE_STEP = 100;

export const DEFAULT_MORTGAGE_MAX = 8000000;
export const DEFAULT_MORTGAGE_INTEREST_MAX = 7;

export const DEFAULT_ADDITIONAL_HOUSING_MORTGAGE_MAX = 15000000;

export const DEFAULT_MAINTENANCE = {
    [HousingType.condominium]: 700,
    [HousingType.house]: 4000,
    [HousingType.cottage]: 2100,
};
export const DEFAULT_CONDOMINIUM_FEE = 3000;
export const MIN_FEE = 0;
export const FEE_STEP = 100;
export const MAX_FEE = 20000;

export const MIN_CAR_COST = 0;
export const CAR_STEP = 500;
export const MAX_CAR_COST = 20000;

export const MIN_STUDENT_LOAN_AMOUNT = 0;
export const STUDENT_LOAN_AMOUNT_STEP = 5000;
export const MAX_STUDENT_LOAN_AMOUNT = 500000;

export const MIN_STUDENT_LOAN_AMORTIZATION = 0;
export const STUDENT_LOAN_AMORTIZATION_STEP = 100;
export const MAX_STUDENT_LOAN_AMORTIZATION = 10000;

export const MIN_PRIVATE_LOAN_AMOUNT = 0;
export const PRIVATE_LOAN_AMOUNT_STEP = 5000;
export const MAX_PRIVATE_LOAN_AMOUNT = 500000;

export const MIN_PRIVATE_LOAN_AMORTIZATION = 0;
export const PRIVATE_LOAN_AMORTIZATION_STEP = 100;
export const MAX_PRIVATE_LOAN_AMORTIZATION = 10000;

export const DEFAULT_HOUSING_PRICE = 3000000;

export const DEFAULT_SAVINGS_ACCOUNT_AMOUNT_MAX = 1000000;

export const DEFAULT_HOUSEHOLD_PARAMETERS = {
    calculationType: undefined,
    price: DEFAULT_HOUSING_PRICE,
    estimatedValue: DEFAULT_HOUSING_PRICE,
    mortgages: [
        {
            amount: 2000000,
            amortization: DEFAULT_MORTGAGE_AMORTIZATION,
            interest: DEFAULT_MORTGAGE_INTEREST,
        },
    ],
    amortization: 5000,
    amortizationType: AmortizationType.flat,
    housingType: undefined,
    maintenance: undefined,
    fee: undefined,
    propertyTax: DEFAULT_PROPERTY_TAX,
    adults: [
        {
            age: DEFAULT_ADULT_AGE,
            income: DEFAULT_ADULT_INCOME,
        },
    ],
};

export const DEFAULT_KALP_PARAMETERS = {
    children: [],
    carCost: 0,
    additionalHousing: {
        state: InputState.NotSpecified,
        data: undefined,
    },
};

export const DEFAULT_LOAN_PARAMETERS = {
    studentLoans: [],
    privateLoans: [],
    carLoans: [],
    cardCredits: [],
};

export const DEFAULT_SAVINGS_PARAMETERS = {
    accountAmount: 0,
    accountMonthlySavings: 0,
    investmentsAmount: 0,
    investmentsMonthlySavings: 0,
};

export const MONTHS_IN_YEAR = 12;
export const RETIREMENT_AGE = 66;
export const MAX_CHILD_AGE = 20;

export const ESTIMATED_SALARY_INCREASE_RATE = 0.018;

export const FALLBACK_INTEREST_RATE = 0.02;

export const LOW_DOWNPAYMENT_RATE = 0.3;
export const HIGH_DOWNPAYMENT_RATE = 0.5;

export const LOAN_TO_INCOME_THRESHOLD = 4.5;
export const HIGH_LOAN_TO_VALUE_THRESHOLD = 0.7;
export const LOW_LOAN_TO_VALUE_THRESHOLD = 0.5;

export const HIGH_AMORTIZATION_RATE_REQUIREMENT = 0.02;
export const LOW_AMORTIZATION_RATE_REQUIREMENT = 0.01;
export const INCOME_BASED_REQUIREMENT = 0.01;
export const ZERO_AMORTIZATION_RATE = 0.0;

export const DEDUCTION_THRESHOLD = 100000;
export const BASE_DEDUCTION_RATE = 0.3;
export const EXTRA_DEDUCTION_RATE = 0.21;
export const BASE_DEDUCTION_FACTOR = 0.7;
export const EXTRA_DEDUCTION_FACTOR = 0.79;

export const DEFAULT_STANDARD_VALUES: IStandardValues = {
    singleAdultHouseholdExpense: 11400,
    twoAdultHouseholdExpense: 18500,
    childFullTimeExpense: 4100,
    childHalfTimeExpense: 2050,
};
