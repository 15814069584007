import type { DataPoint } from "../models/result";
import type { ResultBuilder } from "./result-builder";

export class SectionBuilder {
    result: Array<number>;
    name: string;
    private resultBuilder: ResultBuilder;

    constructor(name: string, resultBuilder: ResultBuilder, numberOfYears: number) {
        this.name = name;
        this.resultBuilder = resultBuilder;
        this.result = new Array(numberOfYears);
    }

    addTooltip(year: number, key: string) {
        this.resultBuilder.addTooltip(year, key);
    }

    addYear(year: number, data: number) {
        this.result[year] = data;
    }

    addToYear(year: number, data: number) {
        if (!Number.isFinite(this.result[year])) {
            this.result[year] = data;
        } else {
            this.result[year] += data;
        }
    }

    fromData(result: DataPoint) {
        this.result = result.future;
    }

    getResult() {
        return {
            now: this.result[0],
            future: this.result,
        };
    }

    getYear(year: number) {
        return this.result[year];
    }
}
