import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { type ITipsModuleProps, tipsModule } from "../../utility/module-store";
import { type IRootState, setMortgageScenario } from "../../reducers/root-reducer";
import { getMortgage, isLoanOutsideLTVLimits } from "../../functions/household";
import { MortgageCalculationType } from "../../models/mortgage";
import { formatLocalAmount } from "../../utility/number-formatter";
import DisclaimerComponent from "../../components/typography/disclaimer-component";
import { processMarkdown } from "../../utility/markdown-processor";
import { isBankKalpPositivForLTV } from "../../functions/kalp";
import { getHousingPriceByCalculationType } from "../../selectors/household";
import { applyBankKalpScenariosToUserData } from "../../functions/calculations";
import { AccessibleButton } from "../../components/accessibility/accessible-components";
import { householdIncomeForecastForYear } from "../../functions/income";
import { MONTHS_IN_YEAR } from "../../defaults";

function HighLTVTipsComponent(props: ITipsModuleProps) {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { minDownpaymentRate, userData, scenarioData } = props.rootState;
    const appliedUserData = applyBankKalpScenariosToUserData(userData, scenarioData, 0);
    const houseValue = getHousingPriceByCalculationType(appliedUserData);
    const maxLTVRatio = 1 - minDownpaymentRate;
    const maxLTVLoan = Math.floor((houseValue * maxLTVRatio) / 1000) * 1000;
    const currentMortgage = getMortgage(appliedUserData) || 0;
    const downpaymentDelta = currentMortgage - maxLTVLoan;

    const discountedInterest = props.configuration?.discounts
        .sort((first, second) => first.rate - second.rate)
        .find((discount) => discount.rate >= maxLTVRatio);
    const mortgageInterestRate = Number.isFinite(discountedInterest?.interest) && { interest: discountedInterest.interest };

    const setScenario = () => {
        props.onActivated();
        dispatch(
            setMortgageScenario({
                value: houseValue,
                mortgage: maxLTVLoan,
                mortgageInterestRate,
            }),
        );
    };

    return (
        <div>
            <h4
                // biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
                dangerouslySetInnerHTML={{
                    __html: processMarkdown(
                        t("tips:amortise-to-maxltv", {
                            downpaymentDelta: formatLocalAmount(downpaymentDelta),
                            maxLTVRatio: maxLTVRatio * 100,
                            maxLTVLoan: formatLocalAmount(maxLTVLoan),
                        }),
                    ),
                }}
            />
            <AccessibleButton onClick={setScenario}>{t("tips:set-scenario")}</AccessibleButton>
            <DisclaimerComponent>
                <span
                    // biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
                    dangerouslySetInnerHTML={{
                        __html: processMarkdown(
                            t("tips:amortise-to-maxltv-disclaimer", {
                                maxLTVRatio: maxLTVRatio * 100,
                            }),
                        ),
                    }}
                />
            </DisclaimerComponent>
            <DisclaimerComponent>{t("tips:no-offer")}</DisclaimerComponent>
        </div>
    );
}

function isLoanApprovedWithExtraDownpaymentAsIncome(state: IRootState, maxLTVRatio: number): boolean {
    const currentUserData = applyBankKalpScenariosToUserData(state.userData, state.scenarioData, state.calculationInterestRate);
    const { netIncome } = householdIncomeForecastForYear(currentUserData, 0);
    const yearlyNetIncome = netIncome * MONTHS_IN_YEAR;
    const currentMortgageValue = getMortgage(currentUserData);
    const maxLTVMortgage = getHousingPriceByCalculationType(currentUserData) * maxLTVRatio;
    const extraDownpayment = currentMortgageValue - maxLTVMortgage;
    return yearlyNetIncome > extraDownpayment;
}

function isTipsActive(state: IRootState) {
    const maxLTVRatio = 1 - state.minDownpaymentRate;
    return (
        isLoanOutsideLTVLimits(state, { maxLTVRatio }) &&
        isBankKalpPositivForLTV(state, maxLTVRatio) &&
        isLoanApprovedWithExtraDownpaymentAsIncome(state, maxLTVRatio)
    );
}

export default tipsModule(
    "high-ltv-move-loan",
    (state: IRootState) => !!state.userData && state.userData.household.calculationType === MortgageCalculationType.move && isTipsActive(state),
)(HighLTVTipsComponent);
