import * as React from "react";
import { Trans } from "react-i18next";
import classNames from "classnames";
import { useSelector } from "react-redux";

import type { DataPoint } from "../models/result";
import { dynamicRounding } from "../utility/number-formatter";
import { type Sections, ViewMode, type IRootState } from "../reducers/root-reducer";
import { AccessibleDiv } from "./accessibility/accessible-components";
import DisclaimerComponent from "./typography/disclaimer-component";
import { getFutureValue, renderScenarioFuture, renderScenarioNow } from "../utility/datapoints";
import InViewAnimated from "./animation/in-view-animated";
import { CSSAnimation } from "./wrappers/animations";
import { NoTranslation } from "./wrappers/no-translation";

export interface IProps {
    section: Sections;
    hasData: boolean;
    onToggle: (Sections, hasData: boolean, expandResult: boolean) => void;
    disabled: boolean;
    children: React.ReactNode;
}

export function ResultComponent(props: IProps) {
    const { selectedSection, selectedViewMode, sectionCollapsed } = useSelector((state: IRootState) => {
        return {
            selectedSection: state.selectedSection,
            selectedViewMode: state.selectedViewMode,
            sectionCollapsed: state.sectionCollapsed,
        };
    });

    const toggle = () => {
        if (props.onToggle) {
            props.onToggle(props.section, props.hasData, selectedViewMode === ViewMode.Input);
        }
    };

    const classNames = () => {
        const containerClass = props.section;
        const collapsed = selectedSection !== props.section;

        const classes = [];
        if (containerClass) {
            classes.push(containerClass);
        }
        if (collapsed) {
            classes.push("collapsed");
        }
        if (props.disabled) {
            classes.push("disabled");
        }

        return classes.join(" ");
    };

    const { hasData, disabled, section } = props;
    const showResult = selectedViewMode === ViewMode.Result;
    const collapsed = selectedSection !== props.section || sectionCollapsed;
    const children = React.Children.map(props.children, (child: React.ReactElement) => {
        if (child.type === ResultHeaderComponent) {
            return React.cloneElement(child, {
                onToggle: toggle,
                collapsed,
                hasData,
                showResult,
                disabled,
            });
        }

        if (child.type === ResultBodyComponent) {
            return React.cloneElement(child, {
                collapsed,
            });
        }

        return child;
    });

    return (
        <section id={`econans-section-${section}`} className={classNames()} data-cy={section} role="table">
            {children}
        </section>
    );
}

export interface IHeaderProps {
    title: string;
    hasData?: boolean;
    data?: DataPoint;
    collapsed?: boolean;
    disabled?: boolean;
    onToggle?: () => void;
    section: string;
    showResult?: boolean;
}

export class ResultHeaderComponent extends React.Component<IHeaderProps> {
    private newResultHeader = true;

    componentDidMount() {
        this.newResultHeader = false;
    }

    toggle() {
        if (this.props.onToggle) {
            this.props.onToggle();
        }
    }

    render() {
        const { section, title, showResult, disabled, hasData, collapsed } = this.props;

        return (
            <AccessibleDiv
                className={classNames("result-header", {
                    disabled,
                    clickable: !disabled && (hasData || collapsed),
                })}
                data-cy={`${section}-header`}
                onClick={this.toggle.bind(this)}
                aria-disabled={disabled}
                role="row"
            >
                <div className="title" role="rowheader">
                    <p className="subtitle_1">{title}</p>
                </div>

                {showResult ? this.renderResult() : this.renderInput()}
            </AccessibleDiv>
        );
    }

    renderResult() {
        const { section, data, collapsed } = this.props;

        if (!data) {
            return (
                <React.Fragment>
                    <div>
                        {collapsed && (
                            <DisclaimerComponent>
                                <Trans ns="common" i18nKey="missing-data" />
                            </DisclaimerComponent>
                        )}
                    </div>
                    <div>
                        <i className={`icon-arrow-up toggle-result${collapsed ? " toggled" : ""}`} />
                    </div>
                </React.Fragment>
            );
        }

        const showScenarioNow = renderScenarioNow(data);
        const showScenarioFuture = renderScenarioFuture(data);
        const sectionElement = document.querySelector(`#econans-section-${section}`) as HTMLElement;

        return (
            <React.Fragment>
                <div data-cy="current-summary" role="cell">
                    {showScenarioNow && (
                        <p data-cy="scenario" className="subtitle_1">
                            <InViewAnimated
                                inViewElement={sectionElement}
                                value={`${dynamicRounding(data.scenarioNow)} kr`}
                                noInitialAnimation={this.newResultHeader}
                            />
                        </p>
                    )}
                    <NoTranslation>
                        <p
                            data-cy="actual"
                            className={`subtitle_1 ${classNames({
                                actual: showScenarioNow,
                            })}`}
                        >
                            {`${dynamicRounding(data.now)} kr`}
                        </p>
                    </NoTranslation>
                </div>

                <div data-cy="future-summary" role="cell">
                    {showScenarioFuture && (
                        <p data-cy="scenario" className="subtitle_2">
                            <InViewAnimated
                                inViewElement={sectionElement}
                                value={`${dynamicRounding(getFutureValue(data.scenarioFuture))} kr`}
                                noInitialAnimation={this.newResultHeader}
                            />
                        </p>
                    )}
                    <NoTranslation>
                        <p
                            data-cy="actual"
                            className={`subtitle_2 ${classNames({
                                actual: showScenarioFuture,
                            })}`}
                        >
                            {`${dynamicRounding(getFutureValue(data.future))} kr`}
                        </p>
                    </NoTranslation>
                </div>
                <div>
                    <i className={`icon-arrow-up toggle-result${collapsed ? " toggled" : ""}`} />
                </div>
            </React.Fragment>
        );
    }

    renderInput() {
        const { hasData, collapsed } = this.props;
        let status = "started";
        if (collapsed) {
            status = hasData ? "completed" : "incomplete";
        }
        const iconStatus = classNames({
            "icon-status-complete": hasData,
            "icon-status-incomplete": !hasData,
        });

        return (
            <React.Fragment>
                <div />
                <div>
                    <p className="subtitle_2">
                        <Trans ns="common" i18nKey={status} />
                    </p>
                </div>
                <div>
                    <i className={`icon ${iconStatus}`} />
                </div>
            </React.Fragment>
        );
    }
}

export function ResultBodyComponent(props: { collapsed?: boolean; children: React.ReactElement }) {
    const viewMode = useSelector((state: IRootState) => state.selectedViewMode);
    const animationClassName = viewMode === ViewMode.Input ? "animate-collapse-input" : "animate-collapse";

    return (
        <CSSAnimation in={!props.collapsed} timeout={300} classNames={animationClassName} unmountOnExit>
            <div className="result-body">{props.children}</div>
        </CSSAnimation>
    );
}
