import createAccessibleComponent from "./generic-component";

export const AccessibleAnchor = createAccessibleComponent<HTMLAnchorElement>("a");

export const AccessibleButton = createAccessibleComponent<HTMLButtonElement>("button");

export const AccessibleDiv = createAccessibleComponent<HTMLDivElement>("div");

export const AccessibleIdiomaticText = createAccessibleComponent<HTMLElement>("i");

export const AccessibleLi = createAccessibleComponent<HTMLLIElement>("li");

export const AccessibleSpan = createAccessibleComponent<HTMLSpanElement>("span");

export const AccessibleHeading = createAccessibleComponent<HTMLHeadingElement>("h4");
