import * as React from "react";
import IndataStepComponent from "../../indata-step";
import { useSelector, useDispatch } from "react-redux";
import { type IRootState, setCarLoanAmount, setCarLoanAmortization } from "../../../reducers/root-reducer";
import NumberComponent from "../../form-controls/number-component";
import { useTranslation } from "react-i18next";
import OverlineComponent from "../../typography/overline-component";

export default function CarLoanFirstStepComponent(props) {
    const { amount, amortization } = useSelector((state: IRootState) => {
        return {
            amount: state.userEditingData.loans.carLoans[props.index].amount,
            amortization: state.userEditingData.loans.carLoans[props.index].amortization,
        };
    });

    const dispatch = useDispatch();
    const { t } = useTranslation();

    function onChangeFirst(value: number) {
        props.onValidate(true);
        dispatch(setCarLoanAmount({ index: props.index, value: value }));
    }

    function onChangeSecond(value: number) {
        props.onValidate(true);
        dispatch(setCarLoanAmortization({ index: props.index, value: value }));
    }

    React.useEffect(() => {
        props.onValidate(true);
    });

    return (
        <IndataStepComponent active={props.active}>
            <OverlineComponent>{t("loan:carloan-enumeration", { index: props.index + 1 })}</OverlineComponent>
            <NumberComponent
                name={`loan_carloan_${props.index}_amount`}
                unit={t("common:sek")}
                onChange={onChangeFirst}
                min={0}
                step={5000}
                defaultMax={500000}
                label={t("loan:input-carloan-amount")}
                value={amount}
            />
            <NumberComponent
                name={`loan_carloan_${props.index}_amortization`}
                unit={t("common:sek")}
                onChange={onChangeSecond}
                min={0}
                step={100}
                defaultMax={10000}
                label={t("loan:input-carloan-amortization")}
                value={amortization}
            />
        </IndataStepComponent>
    );
}
