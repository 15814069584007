import * as React from "react";
import IndataStepComponent from "../../indata-step";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useTracking } from "react-tracking";
import { trackUserInput, UserInputActions } from "../../../utility/analytics";
import { getMortgageCalculationTypes } from "../../../selectors/mortgage";
import { type IRootState, setMortgageCalculationType } from "../../../reducers/root-reducer";
import type { MortgageCalculationType } from "models/mortgage";
import RadioComponent from "../../form-controls/radio-component";

export default function HousingCalculationTypeStepComponent(props) {
    const { calculationTypes, calculationType } = useSelector((state: IRootState) => {
        return {
            calculationTypes: getMortgageCalculationTypes(state.mortgage),
            calculationType: state.userEditingData?.household?.calculationType,
        };
    });

    const { t } = useTranslation();
    const tracking = useTracking();

    const dispatch = useDispatch();

    function start(type: MortgageCalculationType) {
        new Promise<void>((resolve) => {
            dispatch(setMortgageCalculationType({ type }));
            resolve();
        }).then(() => {
            props.nextStep();
        });
        tracking.trackEvent(trackUserInput(UserInputActions.STARTED, `${type}-mortgages`));
    }

    React.useEffect(() => {
        props.onValidate(false);
    });

    const options = calculationTypes.map((type) => {
        return {
            value: type,
            label: t(`dynamic:mortgage-calculation-type-${type}`),
        };
    });
    return (
        <IndataStepComponent active={props.active}>
            <RadioComponent label={t("housing:input-calculation-type-instruction")} value={calculationType} options={options} onChange={start} />
        </IndataStepComponent>
    );
}
