export function fromDecimal(stringNumber: string): number {
    const thousandSeparator = (1111).toLocaleString().replace(/1/g, "");
    const decimalSeparator = (1.1).toLocaleString().replace(/1/g, "");

    return Number.parseFloat(stringNumber.replace(new RegExp(`\\${thousandSeparator}`, "g"), "").replace(new RegExp(`\\${decimalSeparator}`), "."));
}

export function formatLocalAmount(value, minDecimals = 0, maxDecimals = 0, options = {}): string {
    const formatter = new Intl.NumberFormat(
        "sv-SE",
        Object.assign(
            {
                minimumFractionDigits: minDecimals,
                maximumFractionDigits: maxDecimals,
            },
            options,
        ),
    );

    return formatter.format(value);
}

export function dynamicRounding(value: number): string {
    if (value === undefined || value === null || Number.isNaN(value)) {
        return "-";
    }

    return formatLocalAmount(value);
}

export function formatInterest(interest: number): string {
    return formatLocalAmount(Math.round(interest * 10000) / 100, 2, 2);
}
