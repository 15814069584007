import * as React from "react";
import IndataStepComponent from "../../indata-step";
import { useSelector, useDispatch } from "react-redux";
import { type IRootState, setCarCost } from "../../../reducers/root-reducer";
import NumberComponent from "../../form-controls/number-component";
import { useTranslation } from "react-i18next";
import { CAR_STEP, MAX_CAR_COST, MIN_CAR_COST } from "../../../defaults";
import DisclaimerComponent from "../../typography/disclaimer-component";

export default function KalpCarStepComponent(props) {
    const { cost } = useSelector((state: IRootState) => {
        return {
            cost: state.userEditingData?.kalp?.carCost || MIN_CAR_COST,
        };
    });

    const dispatch = useDispatch();
    const { t } = useTranslation();

    function onChangeCost(value: number) {
        props.onValidate(true);
        dispatch(setCarCost(value));
    }

    React.useEffect(() => {
        props.onValidate(true);
    });

    return (
        <IndataStepComponent active={props.active}>
            <NumberComponent
                name="kalp_car"
                unit={t("common:sek")}
                onChange={onChangeCost}
                min={MIN_CAR_COST}
                step={CAR_STEP}
                defaultMax={MAX_CAR_COST}
                label={t("kalp:input-car")}
                value={cost}
            />
            <DisclaimerComponent>{t("kalp:input-car-disclaimer")}</DisclaimerComponent>
        </IndataStepComponent>
    );
}
