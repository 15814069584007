import * as React from "react";
import { useTranslation } from "react-i18next";
import DisclaimerComponent from "./typography/disclaimer-component";

export interface MenuProps {
    showPrognosis: boolean;
}

export default function PrognosisMenu(props: MenuProps) {
    const { showPrognosis } = props;
    const { t } = useTranslation();

    return (
        <div className="prognosis-menu">
            {showPrognosis && (
                <>
                    <div />
                    <div>
                        <DisclaimerComponent>{t("prognosis:today")}</DisclaimerComponent>
                    </div>
                    <div>
                        <DisclaimerComponent>{t("prognosis:in-ten-years")}</DisclaimerComponent>
                    </div>
                    <div />
                </>
            )}
        </div>
    );
}
