import * as React from "react";
import * as d3 from "d3";

import { AxisBottom, AxisLeft } from "./axis";
import { GridX, GridY } from "./grid";
import type { LinearPoint } from "../../models/linear";
import Legend from "./legend";
import type { DataPoint } from "../../models/result";
import { TooltipEvent } from "../../utility/analytics";
import { TotalArea } from "./total-area";
import classNames from "classnames";

export interface IInfoTooltipProps {
    year: number;
    text: string | JSX.Element;
}

export interface IChartProps {
    id: string;
    height?: number;
    width?: number;
    xTickFormat?: (x: number) => string;
    yTickFormat?: (y: number) => string;
    xTicks?: number;
    yTicks?: number;
    className?: string;
    labels: Array<string>;
    infoTooltips?: Array<IInfoTooltipProps>;
    summary?: DataPoint;
    onEngagement: (label: TooltipEvent) => void;
    totalAreaOnTop: boolean;
    children: React.ReactNode;
}

export interface IChartState {
    labels: Array<string>;
    labelWidth: number;
    legendHeight: number;
    activeInfoTooltip?: number;
    hoveredInfoTooltip?: number;
    infoTooltipX?: number;
    infoTooltipY?: number;
    maxWidth?: number;
    currentInfoTooltipButton?: HTMLElement;
}

export class Chart extends React.Component<IChartProps, IChartState> {
    private height: number;
    private width: number;
    private xScale: d3.ScaleLinear<number, number>;
    private yScale: d3.ScaleLinear<number, number>;
    private chartContainer: HTMLDivElement;
    private infoTooltipContainer: HTMLDivElement;
    private resizeFn: ReturnType<typeof setTimeout>;
    private svgRef = React.createRef<SVGSVGElement>();
    private resizeCallback: (this: Window, ev: UIEvent) => void;

    constructor(props) {
        super(props);

        this.state = {
            labels: props.labels,
            labelWidth: 0,
            legendHeight: 100,
            activeInfoTooltip: undefined,
        };
        this.resizeCallback = this.handleResize.bind(this);
    }

    createChart() {
        const { height, width } = this.props;

        this.height = height || 500;
        this.width = width || 460;

        const points = this.getChildPoints();

        let yMax = d3.max(points, ({ y }) => {
            return y;
        });

        const yMin = Math.min(
            0,
            d3.min(points, ({ y }) => {
                return y;
            }),
        );

        const marginFactor = 1.15;
        yMax = yMax > Math.abs(yMin) ? yMax * marginFactor : Math.abs(yMin);

        this.xScale = d3
            .scaleLinear()
            .domain([
                0,
                d3.max(points, ({ x }) => {
                    return x;
                }),
            ])
            .rangeRound([0, this.width]);

        this.yScale = d3.scaleLinear().domain([yMin, yMax]).rangeRound([this.height, 0]).nice();
    }

    setLabelWidth() {
        const widths = [];
        d3.selectAll("svg g.axis-y text").each(function () {
            widths.push((this as SVGTextElement).getComputedTextLength());
        });
        if (widths.length === 0) {
            return 0;
        }
        const maxWidth = Math.round(
            widths.reduce((prev, current) => {
                return current > prev ? current : prev;
            }),
        );

        if (maxWidth !== this.state.labelWidth) {
            this.setState({ labelWidth: maxWidth });
        }
    }

    handleResize() {
        clearTimeout(this.resizeFn);
        this.resizeFn = setTimeout(() => {
            this.setLabelWidth();
        }, 200);
    }

    componentDidMount() {
        this.setLabelWidth();
        this.addHoverListener();
        window.addEventListener("resize", this.resizeCallback);
    }

    getChildPoints(): Array<LinearPoint> {
        const { children } = this.props;
        let childPoints: Array<LinearPoint> = [];

        if (!children) return childPoints;

        React.Children.map(this.props.children, (child: React.ReactElement) => {
            const { points } = child.props as IGraphProps;
            if (points != null && Array.isArray(points) && points.length > 0) childPoints = childPoints.concat(points);
        });

        return childPoints;
    }

    addHoverListener() {
        const xScale = this.xScale;
        const width = this.width;
        const height = this.height;
        const line = document.querySelector(".chart-hover-line");

        d3.select(this.svgRef.current)
            .on("mousemove", (event) => {
                const mouseX = d3.pointer(event)[0];
                const mouseY = d3.pointer(event)[1];
                const mouseOverChart = mouseX <= width && mouseX >= 0 && mouseY <= height && mouseY >= 0;

                if (mouseOverChart) {
                    const year = Math.round(xScale.invert(mouseX));
                    const xValue = xScale(year);

                    (line as HTMLElement).style.setProperty("display", "inline", "important");
                    line.setAttribute("x1", xValue.toString());
                    line.setAttribute("x2", xValue.toString());
                }
            })
            .on("mouseout", () => {
                (line as HTMLElement).style.display = null;
            });
    }

    updateInfoTooltipPosition() {
        if (this.infoTooltipContainer) {
            const chartRect = this.chartContainer.getBoundingClientRect();
            const tooltipWidth = this.infoTooltipContainer.getBoundingClientRect().width;
            const xPos = Math.min(this.state.infoTooltipX, chartRect.width - tooltipWidth);
            this.setState({ infoTooltipX: xPos });
        }
        if (this.state.activeInfoTooltip >= 0) {
            this.infoTooltipContainer.style.setProperty("visibility", "visible", "important");
        }
    }

    onMouseEnterInfoTooltip(index: number, event: React.MouseEvent<HTMLElement>) {
        if (index === this.state.hoveredInfoTooltip) {
            return;
        }
        this.props.onEngagement(TooltipEvent.INFO_TOOLTIP);
        this.setState({ hoveredInfoTooltip: index });
        const chartRect = this.chartContainer.getBoundingClientRect();
        const clientRect = event.currentTarget.getBoundingClientRect() as DOMRect;

        const xPos = Math.max(0, clientRect.x - chartRect.x);
        const yPos = clientRect.y - chartRect.y + clientRect.height + 6;
        this.setState({
            activeInfoTooltip: index,
            infoTooltipX: xPos,
            infoTooltipY: yPos,
            maxWidth: chartRect.width,
            currentInfoTooltipButton: event.target as HTMLElement,
        });
        setTimeout(this.updateInfoTooltipPosition.bind(this));
    }

    onMouseLeaveInfoTooltip() {
        this.setState({ hoveredInfoTooltip: undefined });
        this.setState({
            activeInfoTooltip: undefined,
            currentInfoTooltipButton: undefined,
        });
    }

    componentDidUpdate() {
        if (this.props.labels.length !== this.state.labels.length) {
            this.setState({ labels: this.props.labels });
        } else {
            for (let idx = 0; idx < this.props.labels.length; idx++) {
                if (this.props.labels[idx] !== this.state.labels[idx]) {
                    this.setState({ labels: this.props.labels });
                    break;
                }
            }
        }

        this.infoTooltipContainer.style.setProperty("visibility", "hidden", "important");
        const line = document.querySelector(".chart-hover-line");
        (line as HTMLElement).style.display = null;
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resizeCallback, false);
        d3.select(this.svgRef.current).on("mousemove", null);
        d3.select(this.svgRef.current).on("mouseout", null);
    }

    render() {
        this.createChart();

        const { id, labels, className, xTickFormat, yTickFormat, xTicks, yTicks, totalAreaOnTop } = this.props;
        const { labelWidth, legendHeight } = this.state;

        const calculatedWidth = this.width + labelWidth + 62;
        const calculatedHeight = this.height + legendHeight + 40;

        const chartProps = {
            className: "chart",
            id: id,
            viewBox: `-${labelWidth + 22} -20 ${calculatedWidth} ${calculatedHeight}`,
            preserveAspectRatio: "xMidYMin",
        };

        if (className) {
            chartProps.className = `${chartProps.className} ${className}`;
        }

        const graphProps = {
            parentId: id,
            height: this.height,
            xScale: this.xScale,
            yScale: this.yScale,
        };

        const children = React.Children.map(this.props.children, (child: React.ReactElement) => React.cloneElement(child, graphProps));

        const infoTooltips = ((this.props.summary && this.props?.infoTooltips) || []).map((tt, idx) => {
            const isHovered = this.state.hoveredInfoTooltip === idx;
            const value = this.props.summary.scenarioFuture[tt.year];
            return (
                <g
                    // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                    key={idx}
                    data-cy="info-tooltip"
                    className="info-tooltip-circles"
                    onMouseEnter={this.onMouseEnterInfoTooltip.bind(this, idx)}
                    onMouseLeave={this.onMouseLeaveInfoTooltip.bind(this, idx)}
                    onKeyDown={this.onMouseEnterInfoTooltip.bind(this, idx)}
                >
                    <circle className="info-tooltip-outer" cx={this.xScale(tt.year)} cy={this.yScale(value)} r={isHovered ? 22 : 20} />
                    <circle className="info-tooltip-mid" cx={this.xScale(tt.year)} cy={this.yScale(value)} r={isHovered ? 17 : 15} />
                    <circle className="info-tooltip-inner" cx={this.xScale(tt.year)} cy={this.yScale(value)} r={isHovered ? 12 : 10} />
                </g>
            );
        });

        const totalArea = (
            <TotalArea
                id={id}
                height={this.height}
                width={this.width}
                xScale={this.xScale}
                yScale={this.yScale}
                summary={this.props.summary}
                totalAreaOnTop={totalAreaOnTop}
                onEngagement={this.props.onEngagement}
            />
        );

        return (
            <div
                className="chart-container"
                ref={(node) => {
                    this.chartContainer = node;
                }}
            >
                <div className="tooltip-modal area-tooltip-modal">
                    <h5>&nbsp;</h5>
                    <h6>&nbsp;</h6>
                </div>
                <div
                    className="tooltip-modal info-tooltip-modal"
                    data-cy="info-tooltip-modal"
                    ref={(node) => {
                        this.infoTooltipContainer = node;
                        if (node) {
                            if (!(this.state.activeInfoTooltip >= 0) || !this.props.infoTooltips[this.state.activeInfoTooltip]) {
                                node.style.setProperty("visibility", "hidden", "important");
                            }
                            if (this.state.maxWidth) {
                                node.style.setProperty("max-width", this.state.maxWidth.toString(), "important");
                            }
                            node.style.setProperty("left", `${this.state.infoTooltipX}px`, "important");
                            node.style.setProperty("top", `${this.state.infoTooltipY}px`, "important");
                        }
                    }}
                >
                    <h5>
                        {this.state.activeInfoTooltip >= 0 && this.props.infoTooltips[this.state.activeInfoTooltip]
                            ? this.props.infoTooltips[this.state.activeInfoTooltip].text
                            : ""}
                    </h5>
                </div>
                <svg {...chartProps} ref={this.svgRef}>
                    <filter id="drop-shadow" height="130%">
                        <feGaussianBlur in="SourceAlpha" stdDeviation="2" />
                        <feOffset dx="0" dy="0" result="offsetblur" />
                        <feComponentTransfer>
                            <feFuncA type="linear" slope="0.5" />
                        </feComponentTransfer>
                        <feMerge>
                            <feMergeNode />
                            <feMergeNode in="SourceGraphic" />
                        </feMerge>
                    </filter>
                    <title>{id}</title>
                    <g id={`${id}-container`}>
                        {!totalAreaOnTop && totalArea}
                        {children}
                        {totalAreaOnTop && totalArea}
                        <AxisBottom scale={this.xScale} height={this.height} ticks={xTicks} tickFormat={xTickFormat} />
                        <AxisLeft scale={this.yScale} ticks={yTicks} tickFormat={yTickFormat} />
                        <g className="chart-hover-lines">
                            <line className="chart-hover-line" x1="0" x2="0" y1="0" y2={this.height} />
                        </g>
                        <GridX scale={this.xScale} height={this.height} ticks={xTicks} />
                        <GridY scale={this.yScale} width={this.width} ticks={yTicks} />
                        {infoTooltips}
                        <g className="area-tooltip-circles">
                            <circle className="area-tooltip-circle" r="15" />
                            <circle className="area-tooltip-circle" r="10" />
                        </g>
                    </g>
                </svg>
                <Legend
                    className={classNames({
                        [`${className}`]: className !== null,
                    })}
                    labels={labels}
                />
            </div>
        );
    }
}

export interface IGraphProps {
    points: Array<LinearPoint>;
    className?: string;
    height?: number;
    parentId?: string;
    xScale?: d3.ScaleLinear<number, number>;
    yScale?: d3.ScaleLinear<number, number>;
}

export abstract class Graph<P extends IGraphProps> extends React.Component<P> {}
