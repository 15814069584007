import type { IRootState } from "../../reducers/root-reducer";
import { tipsModule } from "../../utility/module-store";
import { isTipsActive, InterestDiscountTipsComponent } from "./interest-discount-new-loan";
import { MortgageCalculationType } from "../../models/mortgage";

export default tipsModule(
    "interest-discount-move-loan",
    (state: IRootState, configuration?) =>
        state?.userData?.household?.calculationType === MortgageCalculationType.move && isTipsActive(state, configuration?.discounts),
)(InterestDiscountTipsComponent);
