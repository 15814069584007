import * as React from "react";
import * as d3 from "d3";
import i18next from "i18next";

import { Graph, type IGraphProps } from "./chart";
import type { LinearPoint } from "../../models/linear";
import { formatLocalAmount } from "../../utility/number-formatter";
import { TooltipEvent } from "../../utility/analytics";

export interface IAreaProps extends IGraphProps {
    id?: number;
    label: string;
    onEngagement: (label: TooltipEvent) => void;
}

export class Area extends Graph<IAreaProps> {
    areaRef = React.createRef<SVGPathElement>();
    private resizeFn: ReturnType<typeof setTimeout>;

    componentDidMount() {
        this.addAreaHoverListener();
    }

    componentDidUpdate() {
        this.closeAreaTooltip(this.props.id);
        clearTimeout(this.resizeFn);
        this.resizeFn = setTimeout(() => {
            this.addAreaHoverListener();
        }, 200);
    }

    addAreaHoverListener() {
        const { xScale, points, id } = this.props;
        const tooltipCircles = document.querySelectorAll(".area-tooltip-circle");
        const firstXValue = Math.floor(points[0].x);
        const setAreaTooltipCirclePos = this.setAreaTooltipCirclePos.bind(this);
        const setAreaTooltipModal = this.setAreaTooltipModal.bind(this);
        const closeAreaTooltip = this.closeAreaTooltip;

        d3.select(this.areaRef.current)
            .on("mouseenter", () => this.props.onEngagement(TooltipEvent.AREA_TOOLTIP))
            .on("mousemove", (event) => {
                const year = Math.round(xScale.invert(d3.pointer(event)[0]));
                const arrayIndex = year - firstXValue;
                const isNotValidXValue = !Number.isInteger(points[arrayIndex].x);
                if (isNotValidXValue) {
                    closeAreaTooltip(id);
                    return;
                }
                setAreaTooltipCirclePos(year, tooltipCircles, arrayIndex);
                setAreaTooltipModal(year, tooltipCircles[0], arrayIndex);
            })
            .on("mouseout", () => {
                closeAreaTooltip(id);
            });
    }

    setAreaTooltipCirclePos(year, tooltipCircles, arrayIndex) {
        const { xScale, yScale, points, id } = this.props;
        const yValue = points[arrayIndex].y;
        for (const circle of tooltipCircles) {
            circle.setAttribute("cx", xScale(year).toString());
            circle.setAttribute("cy", yScale(yValue).toString());
            circle.classList.add("active");
        }
        if (Number.isFinite(id)) {
            tooltipCircles[1].classList.add(`circle-${id}`);
        }
    }

    setAreaTooltipModal(year, tooltipCircle, arrayIndex) {
        const areaTooltip = document.querySelector(".area-tooltip-modal");
        this.setAreaTooltipModalContent(areaTooltip, year, arrayIndex);
        this.setAreaTooltipModalPosition(areaTooltip, tooltipCircle, year);
    }

    setAreaTooltipModalContent(areaTooltip, year, arrayIndex) {
        const { points, label, parentId } = this.props;
        const areaAmount = formatLocalAmount(points[arrayIndex].amount);
        areaTooltip.childNodes[0].innerText = `${i18next.exists(`dynamic:${label}`) ? i18next.t(`dynamic:${label}`) : label} ${
            year === 0 ? i18next.t("prognosis:today").toLowerCase() : i18next.t("prognosis:inyears", { year })
        }:`;
        areaTooltip.childNodes[1].innerText = `${areaAmount} ${i18next.t(`dynamic:${parentId}-sek-unit`)}`;
    }

    setAreaTooltipModalPosition(areaTooltip, tooltipCircle, year) {
        const circlePos = tooltipCircle.getBoundingClientRect();
        const chartContainerPos = document.querySelector(".chart-container").getBoundingClientRect();
        const tooltipWidth = areaTooltip.getBoundingClientRect().width;
        const tooltipPosX = year < 5 ? circlePos.left - chartContainerPos.left : circlePos.left - chartContainerPos.left - tooltipWidth + 20;
        const tooltipPosY = circlePos.top - chartContainerPos.top + 25;
        areaTooltip.style.setProperty("left", `${tooltipPosX}px`, "important");
        areaTooltip.style.setProperty("top", `${tooltipPosY}px`, "important");
        areaTooltip.classList.add("active");
    }

    closeAreaTooltip(id) {
        const areaTooltip = document.querySelector(".area-tooltip-modal");
        areaTooltip.classList.remove("active");
        const tooltipCircles = document.querySelectorAll(".area-tooltip-circle");
        for (const circle of tooltipCircles) {
            circle.classList.remove("active");
        }
        if (Number.isFinite(id)) {
            tooltipCircles[1].classList.remove(`circle-${id}`);
        }
    }

    render() {
        const { className, xScale, yScale, height, points, label } = this.props;

        const graphProps = {
            className: "area",
            label,
        };

        if (className) graphProps.className = `${graphProps.className} ${className}`;

        const area = d3
            .area<LinearPoint>()
            .x((d) => {
                return xScale(d.x);
            })
            .y0((d) => {
                return Number.isFinite(d.y0) ? yScale(d.y0) : height;
            })
            .y1((d) => {
                return yScale(d.y);
            });

        return <path {...graphProps} d={area(points)} ref={this.areaRef} />;
    }
}
