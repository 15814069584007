import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import React from "react";
import { ScenarioTemplate, IncompleteStateScenario, type IScenarioProps } from "../scenarios";
import { type IRootState, resetInvestmentScenario, setInvestmentScenario } from "../../reducers/root-reducer";
import NumberComponent from "../form-controls/number-component";
import DisclaimerComponent from "../typography/disclaimer-component";
import { getInvestmentInterest } from "../../functions/savings";
import { ScenarioEvent } from "../../utility/analytics";

export const InvestmentScenario = (props: IScenarioProps) => {
    const { onBack, onEngagement } = props;

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { scenarioMonthly, scenarioInterest } = useSelector((state: IRootState) => {
        return {
            scenarioMonthly: state.scenarioData?.investmentMonthly,
            scenarioInterest: state.scenarioData?.investmentInterest,
        };
    });

    const existingSavings = useSelector((state: IRootState) => !!state.userData?.savings);

    const { userMonthly, userInterest } = useSelector((state: IRootState) => {
        return {
            userMonthly: state.userData?.savings?.investmentsMonthlySavings,
            userInterest: getInvestmentInterest(),
        };
    });

    const scenarioActivated = Number.isFinite(scenarioMonthly);

    const selectedMonthly = Number.isFinite(scenarioMonthly) ? scenarioMonthly : userMonthly;
    const selectedInterest = Number.isFinite(scenarioInterest) ? scenarioInterest : userInterest;

    if (!existingSavings) {
        return (
            <IncompleteStateScenario
                description={t("scenario:scenario-no-savings")}
                name={t("scenario:investment-header")}
                onBack={onBack}
                category={t("dynamic:savings")}
            />
        );
    }

    const resetScenario = () => {
        dispatch(resetInvestmentScenario());
    };
    const setScenarioMonthly = (value) => {
        onEngagement(ScenarioEvent.INVESTMENT);
        dispatch(
            setInvestmentScenario({
                monthly: value,
                interest: selectedInterest,
            }),
        );
    };
    const setScenarioInterest = (value) => {
        onEngagement(ScenarioEvent.INVESTMENT);
        const interest = value / 100;
        dispatch(setInvestmentScenario({ monthly: selectedMonthly, interest }));
    };

    return (
        <ScenarioTemplate
            name={t("scenario:investment-header")}
            onBack={onBack}
            onReset={resetScenario}
            scenarioActivated={scenarioActivated}
            category={t("dynamic:savings")}
        >
            <NumberComponent
                name="investment_scenario_monthly"
                unit={t("common:sek")}
                onChange={setScenarioMonthly}
                min={0}
                step={100}
                defaultMax={10000}
                label={t("scenario:investment-monthly-label")}
                value={selectedMonthly}
            />
            <DisclaimerComponent>{t("scenario:investment-disclaimer")}</DisclaimerComponent>

            <NumberComponent
                name="investment_scenario_interest"
                unit="%"
                onChange={setScenarioInterest}
                min={0}
                step={0.1}
                defaultMax={20}
                label={t("scenario:investment-interest-label")}
                value={selectedInterest * 100}
            />
        </ScenarioTemplate>
    );
};
