import type UserData from "../models/user-data";
import type { HouseholdData } from "../models/user-data";
import type Result from "../models/result";
import type { DataPoint, IResult } from "../models/result";
import forecastSavings from "./savings";
import { forecastLoans } from "./loans";
import { forecastHousingExpenses } from "./household";
import { forecastKalp, forecastKalpWithInterest } from "./kalp";
import { MortgageCalculationType } from "../models/mortgage";
import { mergeTooltips } from "./result-builder";
import type { IHousingLoanScenario, IScenarioData } from "../reducers/root-reducer";
import { DEFAULT_MORTGAGE_AMORTIZATION, DEFAULT_MORTGAGE_INTEREST } from "../defaults";

export function normalizeInterest(interest: number): number {
    return interest / 100.0;
}

export function convertToPercent(interest: number): number {
    return interest * 100;
}

export function convertInterest(interest: number): number {
    return 1 + interest / 100.0;
}

export function compoundInterest(amount: number, interest: number, periods?: number): Array<number> {
    const convertedInterest = convertInterest(interest);
    const activePeriods = periods || 1;

    const result = [amount];
    return result.concat(accumulate(amount, activePeriods, (accumulated) => accumulated * convertedInterest));
}

export function accumulate<T>(amount: T, years: number, fn: (accumulatedAmount: T) => T): Array<T> {
    let balance = amount;
    const results = [];
    for (let i = 0; i < years; ++i) {
        balance = fn(balance);
        results.push(balance);
    }

    return results;
}

export function applyInterest(amount: number, interest: number): number {
    return amount * convertInterest(interest);
}

export function calculateResult(userData: UserData, scenarioData: IScenarioData, calculationInterestRate: number): Result {
    const actualHousingResult = forecastHousingExpenses(userData, 10, scenarioData);
    const kalp = forecastKalp(userData, 10, true);
    const savings = forecastSavings(userData, 10);
    const actual = {
        housing: actualHousingResult.result,
        kalp: kalp ? kalp.result : undefined,
        savings: savings ? savings.result : undefined,
        loans: forecastLoans(userData, 10),
    };

    const scenarioUserData = applyScenariosToUserData(userData, scenarioData);
    const scenarioHousingResult = forecastHousingExpenses(scenarioUserData, 10);
    const scenarioKalp = forecastKalp(scenarioUserData, 10, true);
    const scenarioSavings = forecastSavings(scenarioUserData, 10);
    const scenario = {
        housing: scenarioHousingResult.result,
        kalp: scenarioKalp ? scenarioKalp.result : undefined,
        savings: scenarioSavings ? scenarioSavings.result : undefined,
        loans: forecastLoans(scenarioUserData, 10),
    };

    const results = mergeResults(actual, scenario);
    const housingTooltips = scenarioHousingResult.infoTooltips || [];
    const kalpTooltips = scenarioKalp ? scenarioKalp.tooltips : [];
    const savingsTooltips = scenarioSavings ? scenarioSavings.tooltips : [];
    const mergedKalpTooltips = mergeTooltips(kalpTooltips.concat(housingTooltips));

    return {
        ...results,
        housingTooltips: housingTooltips,
        kalpTooltips: mergedKalpTooltips,
        savingsTooltips: savingsTooltips,
        calculationInterestKalp: forecastKalpWithInterest(applyBankKalpScenariosToUserData(userData, scenarioData, 0), calculationInterestRate),
    };
}

export function applyMortgageScenarioToUserData(household: HouseholdData, scenarioMortgageData: IHousingLoanScenario): HouseholdData {
    if (household?.calculationType === MortgageCalculationType.new) {
        household.price = scenarioMortgageData.housingValue;
        household.downpayment = scenarioMortgageData.housingValue - scenarioMortgageData.mortgage;
    } else if (household.mortgages && household.mortgages.length > 0) {
        const totalMortgages = household.mortgages.map((m) => m.amount).reduce((sum, m) => sum + m);
        household.estimatedValue = scenarioMortgageData.housingValue;
        household.mortgages = household.mortgages.map((m, idx) => {
            if (totalMortgages > 0) {
                return {
                    ...m,
                    amount: (m.amount / totalMortgages) * scenarioMortgageData.mortgage,
                };
            }
            if (idx === 0) {
                return {
                    ...m,
                    amount: scenarioMortgageData.mortgage,
                };
            }
            return { ...m };
        });
    } else {
        household.estimatedValue = scenarioMortgageData.housingValue;
        household.mortgages = [
            {
                amount: scenarioMortgageData.mortgage,
                amortization: DEFAULT_MORTGAGE_AMORTIZATION,
                interest: DEFAULT_MORTGAGE_INTEREST,
            },
        ];
    }

    return household;
}

export function applyBankKalpScenariosToUserData(userData: UserData, scenarioData: IScenarioData, interest: number): UserData {
    let household = { ...userData.household };
    if (scenarioData?.mortgageData) {
        household = applyMortgageScenarioToUserData(household, scenarioData.mortgageData);
    }
    const amortizationScenarioData =
        userData.household.calculationType === MortgageCalculationType.move ? scenarioData?.mortgageAmortization : undefined;

    return {
        ...userData,
        household,
        scenarioDefinedInterest: interest,
        scenarioDefinedMaintenance: scenarioData?.mortgageMaintenance,
        scenarioDefinedChildren: scenarioData?.additionalChild,
        scenarioDefinedAdults: scenarioData?.adultsWorkPartTime,
        scenarioDefinedAmortization: amortizationScenarioData,
    };
}

export function applyScenariosToUserData(userData: UserData, scenarioData?: IScenarioData): UserData {
    let household = { ...userData.household };
    if (scenarioData?.mortgageData) {
        household = applyMortgageScenarioToUserData(household, scenarioData.mortgageData);
    }

    const savings = { ...userData.savings };
    if (Number.isFinite(scenarioData?.investmentMonthly)) {
        savings.investmentsMonthlySavings = scenarioData.investmentMonthly;
    }

    return {
        ...userData,
        household,
        savings,
        scenarioDefinedInterest: scenarioData?.mortgageInterestRate?.interest,
        scenarioDefinedAmortization: scenarioData?.mortgageAmortization,
        scenarioDefinedMaintenance: scenarioData?.mortgageMaintenance,
        scenarioDefinedChildren: scenarioData?.additionalChild,
        scenarioDefinedAdults: scenarioData?.adultsWorkPartTime,
        scenarioDefinedInvestmentInterest: scenarioData?.investmentInterest,
        scenarioDefinedRenovationTarget: scenarioData?.renovationTarget,
        scenarioDefinedRenovationCost: scenarioData?.renovationCost,
        scenarioDefinedRenovationYear: scenarioData?.renovationYear,
    };
}

function mergeResults(actual: Result, scenario: Result): Result {
    const newObj = {};

    for (const prop in actual) {
        if (Object.hasOwn(actual, prop) || Object.hasOwn(scenario, prop)) {
            if (typeof actual[prop] === "undefined") {
                newObj[prop] = undefined;
            } else {
                newObj[prop] = mergeResult(actual[prop], scenario[prop]);
            }
        }
    }

    return newObj;
}

function mergeResult(actual: IResult, scenario: IResult) {
    const newObj = {};

    const unionOfProps = [...new Set([...Object.keys(actual), ...Object.keys(scenario)])];

    for (const prop of unionOfProps) {
        if (Object.hasOwn(actual, prop) || Object.hasOwn(scenario, prop)) {
            newObj[prop] = mergeDatapoints(actual[prop], scenario[prop]);
        }
    }

    return newObj;
}

function mergeDatapoints(actual: DataPoint, scenario: DataPoint) {
    return {
        now: actual?.now || 0,
        future: actual?.future || new Array(scenario.future.length).fill(0),
        scenarioNow: scenario?.now || 0,
        scenarioFuture: scenario?.future || new Array(actual.future.length).fill(0),
    };
}
